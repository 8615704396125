import { calculateSensitivity } from 'helpers/units';

import { GeneratedCAMExerciseDefinition } from '../../types/GeneratedExerciseDefinition';

export const getPredefinedMeissaOtCAMTurnKeyExercises = (): { [key: string]: GeneratedCAMExerciseDefinition } => ({
  cam_turn_key_classic_meissa: {
    type: 'cam-turn-key',
    calibrationFlow: 'cam-turn-key/meissa',
    tags: [],
    cam: {
      main: {
        program: {
          phases: [
            {
              repetitions: 10,
              maxSpeed: 40,
              activeMovementDirection: 'both',
              forceSource: 'torque',
              forceTriggerSource: 'extension',
              trigger: true,
              coupling: {
                force: {
                  extension: {
                    sensitivity: 0,
                    deadband: 0,
                  },
                  torque: {
                    sensitivity: calculateSensitivity(1, 1000),
                    deadband: 2,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both', 'toMin', 'toMax'],
                blockAfterStart: true,
              },
              repetitions: {
                default: 10,
                unit: 'number',
                values: [5, 10, 15, 20, 50, 100],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 40,
                unit: 'deg/s',
                values: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
    },
  },
});
