import { calculateSensitivity } from 'helpers/units';
import { GeneratedCAMExerciseDefinition } from 'libs/exo-session-manager/core/types/GeneratedExerciseDefinition';

export const getPredefinedSidraLegCAMExercises = (): { [key: string]: GeneratedCAMExerciseDefinition } => ({
  cam_isokinetic_classic_concentric_knee: {
    type: 'cam-isokinetic',
    calibrationFlow: 'cam-isokinetic/knee',
    tags: [],
    cam: {
      knee: {
        program: {
          phases: [
            {
              time: 10 * 60,
              activeMovementDirection: 'both',
              maxSpeed: 10,
              trigger: true,
              coupling: {
                force: {
                  knee: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  toes: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  heel: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both', 'toMin', 'toMax'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'toes',
                unit: 'none',
                values: ['knee', 'toes', 'heel'],
                blockAfterStart: true,
              },
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
      ankle: {
        program: null,
      },
    },
  },
  cam_isokinetic_classic_concentric_ankle: {
    type: 'cam-isokinetic',
    calibrationFlow: 'cam-isokinetic/ankle',
    tags: [],
    cam: {
      ankle: {
        program: {
          phases: [
            {
              time: 10 * 60,
              activeMovementDirection: 'both',
              maxSpeed: 30,
              trigger: true,
              coupling: {
                force: {
                  knee: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  toes: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  heel: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both', 'toMin', 'toMax'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'toes',
                unit: 'none',
                values: ['knee', 'toes', 'heel'],
                blockAfterStart: true,
              },
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 30,
                unit: 'deg/s',
                values: [0.2, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
      knee: {
        program: null,
      },
    },
  },
  cam_isokinetic_classic_concentric_ankle_synced: {
    type: 'cam-isokinetic',
    calibrationFlow: 'cam-isokinetic/ankle-synced',
    tags: [],
    cam: {
      knee: {
        program: {
          phases: [
            {
              time: 10 * 60,
              maxSpeed: 10,
              activeMovementDirection: 'both',
              trigger: true,
              coupling: {
                force: {
                  knee: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  toes: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  heel: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both', 'toMin', 'toMax'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'toes',
                unit: 'none',
                values: ['knee', 'toes', 'heel'],
                blockAfterStart: true,
              },
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
      ankle: {
        program: null,
        synchronized: 'knee',
      },
    },
  },

  cam_isokinetic_classic_concentric_knee_synced: {
    type: 'cam-isokinetic',
    calibrationFlow: 'cam-isokinetic/knee-synced',
    tags: [],
    cam: {
      knee: {
        program: null,
        synchronized: 'ankle',
      },
      ankle: {
        program: {
          phases: [
            {
              time: 10 * 60,
              maxSpeed: 10,
              activeMovementDirection: 'both',
              trigger: true,
              coupling: {
                force: {
                  knee: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  toes: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  heel: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both', 'toMin', 'toMax'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'toes',
                unit: 'none',
                values: ['knee', 'toes', 'heel'],
                blockAfterStart: true,
              },
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
    },
  },
  cam_torque_classic_concentric_knee: {
    type: 'cam-torque',
    calibrationFlow: 'cam-torque/knee',
    tags: [],
    cam: {
      knee: {
        program: {
          phases: [
            {
              time: 10 * 60,
              activeMovementDirection: 'both',
              maxSpeed: 10,
              maxSensitivity: calculateSensitivity(2, 10),
              trigger: true,
              coupling: {
                force: {
                  knee: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  toes: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  heel: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20],
                blockAfterStart: false,
              },
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both', 'toMin', 'toMax'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'toes',
                unit: 'none',
                values: ['knee', 'toes', 'heel'],
                blockAfterStart: true,
              },
            },
          ],
        },
      },
      ankle: {
        program: null,
      },
    },
  },
  cam_torque_classic_concentric_ankle: {
    type: 'cam-torque',
    calibrationFlow: 'cam-torque/ankle',
    tags: [],
    cam: {
      knee: {
        program: null,
      },
      ankle: {
        program: {
          phases: [
            {
              time: 10 * 60,
              activeMovementDirection: 'both',
              maxSpeed: 30,
              maxSensitivity: calculateSensitivity(2, 10),
              trigger: true,
              coupling: {
                force: {
                  knee: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  toes: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  heel: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 30,
                unit: 'deg/s',
                values: [0.2, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60],
                blockAfterStart: false,
              },
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both', 'toMin', 'toMax'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'toes',
                unit: 'none',
                values: ['knee', 'toes', 'heel'],
                blockAfterStart: true,
              },
            },
          ],
        },
      },
    },
  },
  cam_torque_classic_concentric_ankle_synced: {
    type: 'cam-torque',
    calibrationFlow: 'cam-torque/ankle-synced',
    tags: [],
    cam: {
      knee: {
        program: {
          phases: [
            {
              time: 10 * 60,
              activeMovementDirection: 'both',
              maxSpeed: 10,
              maxSensitivity: calculateSensitivity(2, 10),
              trigger: true,
              coupling: {
                force: {
                  knee: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  toes: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  heel: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20],
                blockAfterStart: false,
              },
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both', 'toMin', 'toMax'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'toes',
                unit: 'none',
                values: ['knee', 'toes', 'heel'],
                blockAfterStart: true,
              },
            },
          ],
        },
      },
      ankle: {
        program: null,
        synchronized: 'knee',
      },
    },
  },
  cam_torque_classic_concentric_knee_synced: {
    type: 'cam-torque',
    calibrationFlow: 'cam-torque/knee-synced',
    tags: [],
    cam: {
      knee: {
        program: null,
        synchronized: 'ankle',
      },
      ankle: {
        program: {
          phases: [
            {
              time: 10 * 60,
              maxSpeed: 10,
              activeMovementDirection: 'both',
              maxSensitivity: calculateSensitivity(2, 10),
              trigger: true,
              coupling: {
                force: {
                  knee: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  toes: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  heel: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20],
                blockAfterStart: false,
              },
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both', 'toMin', 'toMax'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'toes',
                unit: 'none',
                values: ['knee', 'toes', 'heel'],
                blockAfterStart: true,
              },
            },
          ],
        },
      },
    },
  },
});
