import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { queryFetchFn } from 'config/api';
import { API_URL } from 'config/apiUrl';
import { ExoClinicBackendOpenApiPaths } from 'services/ExoClinicBackendOpenApi';

export const deviceApi = createApi({
  reducerPath: 'deviceApi',
  baseQuery: retry(
    fetchBaseQuery({
      fetchFn: queryFetchFn,
      baseUrl: API_URL,
    }),
  ),
  endpoints: builder => ({
    expectedFirmwareVersion: builder.query<
      ExoClinicBackendOpenApiPaths['/device/update/firmware/valid-version']['get']['response'],
      string | null
    >({
      // id for query is needed because we want to reload firmware versions when device change.
      // This is needed for dummy device to work correctly.
      query: (id: string | null) =>
        `device/update/firmware/valid-version?${new URLSearchParams({ deviceId: id ?? '' }).toString()}`,
      serializeQueryArgs: ({ queryArgs }) => queryArgs ?? '',
      extraOptions: { maxRetries: 100 },
    }),
  }),
});

export const { useExpectedFirmwareVersionQuery } = deviceApi;
