import { emsFlow } from './emsFlow';
import { CalibrationFlowDefinition } from './types';

export const stellaBioCalibrationFlow = {
  emg: {
    initial: 'connect-electrodes',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
    },
    stepsNumber: 3,
    states: {
      'connect-electrodes': {
        next: 'emg-calibration',
        prev: null,
        stepIndex: 1,
        disableAlerts: ['disconnectedElectrode'],
      },
      'emg-calibration': {
        next: null,
        prev: 'connect-electrodes',
        stepIndex: 2,
      },
    },
  },

  ems: {
    initial: 'ems-warnings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
    },
    stepsNumber: 2,
    states: {
      'connect-electrodes': {
        next: 'ems-calibration',
        prev: null,
        stepIndex: 1,
        disableAlerts: ['disconnectedElectrode'],
      },
      ...emsFlow(null, 'connect-electrodes', 2),
    },
  },

  'ems/emg': {
    initial: 'ems-warnings',
    alerts: {
      cableDetached: {
        type: 'warning',
        condition: 'isCableDetached',
        resolveState: 'connect-cable',
      },
      disconnectedElectrode: {
        type: 'warning',
        condition: 'areElectrodesDisconnected',
        resolveState: 'connect-electrodes',
      },
    },
    stepsNumber: 3,
    states: {
      'connect-electrodes': {
        next: 'emg-calibration',
        prev: null,
        stepIndex: 1,
        disableAlerts: ['disconnectedElectrode'],
      },
      'emg-calibration': {
        next: 'ems-calibration',
        prev: 'connect-electrodes',
        stepIndex: 2,
      },
      ...emsFlow(null, 'emg-calibration', 3),
    },
  },
} satisfies CalibrationFlowDefinition;
