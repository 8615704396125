import { Box, Button, Container, ContainerProps, Flex } from '@chakra-ui/react';
import { ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import colors, { Channel } from 'config/theme/colors';
import { ExerciseElectrode, ProgressStep } from 'types/exercise';

import { ChannelBadge } from 'components/common/ChannelBadge';
import { MuscleImage } from 'components/common/MuscleImage';
import { CheckIcon, ExclamationIcon } from 'components/icons';
import { TranslateText } from 'components/texts/TranslateText';

interface StepsProgressProps {
  steps: ProgressStep[] | Partial<ProgressStep>[];
  onSelectStep(index: number): void;
  currentStep: number | null;
  imgUrl?: string;
  imgDescription?: string;
  showChannelBadge?: boolean;
  variant?: ContainerProps['variant'];
  tempProgressHide?: boolean;
  side?: 'right' | 'left';
}

export const ConnectElectrodesStepsProgress = ({
  steps,
  onSelectStep,
  imgUrl = '',
  imgDescription,
  showChannelBadge = true,
  variant = 'electrodesConnection',
  tempProgressHide = false,
  currentStep,
  side,
}: StepsProgressProps) => {
  const backgroundColor = (
    state?: ChannelConnectionQuality,
    touched?: boolean,
    timedOut?: boolean,
    isCurrentStep = false,
  ) => {
    if (!touched) return 'skeletonDarkColor';
    if (isCurrentStep && state === ChannelConnectionQuality.NONE) return 'white';

    switch (state) {
      case ChannelConnectionQuality.WELL:
        return 'stellaBioPrimaryColor';
      case ChannelConnectionQuality.POOR:
        return 'warningColor';
      case ChannelConnectionQuality.NONE:
        return 'errorColor'; //timedOut ? 'errorColor' : 'white';
    }
  };

  const renderIcon = (step: ExerciseElectrode | Partial<ProgressStep>) => {
    switch (step?.status) {
      case ChannelConnectionQuality.WELL:
        return <CheckIcon color="white" />;
      case ChannelConnectionQuality.NONE:
        return <ExclamationIcon color="white" />; // step.timedOut ? <ExclamationIcon color="white" /> : <></>;
      default:
        return <ExclamationIcon color="white" />;
    }
  };
  const validateSelectStep = (idx: number) => {
    if (!steps.some((el, elIdx) => el.status === ChannelConnectionQuality.NONE && elIdx < idx)) {
      onSelectStep(idx);
    }
  };

  const currentMuscle = typeof currentStep === 'number' ? steps[currentStep].muscle : null;

  return (
    <Container variant={variant} backgroundImage={imgUrl} data-testid="connect-electrodes-steps-progress">
      {side && currentMuscle ? (
        <Flex justify="center" pb="10">
          <MuscleImage
            boxSize={{ base: 'md', '2xl': '2xl' }}
            borderRadius="rMd"
            type={currentMuscle.type}
            muscleId={currentMuscle.muscleId}
            electrodes={currentMuscle.channelFeature.includes('ems') ? 'ems' : 'emg'}
            channel={(steps[currentStep!].channelIndex! + 1) as Channel}
          />
        </Flex>
      ) : null}
      <Box w="full" textAlign="center">
        <TranslateText variant="openSans60" color="egzotechPrimaryColor" fontWeight="bold" text={imgDescription} />
      </Box>
      <Box mx="auto">
        {showChannelBadge && (
          <Flex mb="4">
            {steps.map((step, idx) => (
              <ChannelBadge
                channel={step.channelIndex}
                label={step.label}
                mr={idx < steps.length - 1 ? '8' : '0'}
                key={`${step.channelIndex} - ${step.label}`}
                active={true}
                testId="steps-progress-channel-badge"
                size="sm"
              />
            ))}
          </Flex>
        )}

        {/*hide progress in new flow.Agreed with Dominika*/}
        {!tempProgressHide && (
          <Flex pl="1">
            {steps.map((step, idx) => (
              <Flex key={`${step.channelIndex} - ${step.label}`} alignItems="center">
                {idx > 0 && (
                  <Box backgroundColor={step.touched ? 'stellaBioPrimaryColor' : 'skeletonDarkColor'} w="9" h="1" />
                )}
                <Button
                  variant="iconButton"
                  w={7}
                  h={7}
                  bgColor={backgroundColor(step.status, step.touched, step.timedOut, step.currentStep)}
                  borderWidth={1}
                  borderColor={
                    step.currentStep && step.status === ChannelConnectionQuality.NONE
                      ? colors.stellaBioPrimaryColor
                      : backgroundColor(step.status, step.touched, step.timedOut, idx == currentStep)
                  }
                  isDisabled={!step.touched}
                  onClick={() => validateSelectStep(idx)}
                  data-testid="channel-button"
                >
                  {step.touched && renderIcon(step)}
                </Button>
              </Flex>
            ))}
          </Flex>
        )}
      </Box>
    </Container>
  );
};
