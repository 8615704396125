const adductorMagnusLeft = 'lower.left.adductorMagnus';
const adductorMagnusRight = 'lower.right.adductorMagnus';
const adductorPollicisLeft = 'upper.left.adductorPollicis';
const adductorPollicisRight = 'upper.right.adductorPollicis';
const bicepsBrachiiLeft = 'upper.left.bicepsBrachii';
const bicepsBrachiiRight = 'upper.right.bicepsBrachii';
const brachioradialisLeft = 'upper.left.brachioradialis';
const brachioradialisRight = 'upper.right.brachioradialis';
const deltoideusClavicularPartLeft = 'upper.left.deltoideusClavicularPart';
const deltoideusClavicularPartRight = 'upper.right.deltoideusClavicularPart';
const extensorCarpiRadialisBrevisLeft = 'upper.left.extensorCarpiRadialisBrevis';
const extensorCarpiRadialisBrevisRight = 'upper.right.extensorCarpiRadialisBrevis';
const extensorCarpiRadialisLongusLeft = 'upper.left.extensorCarpiRadialisLongus';
const extensorCarpiRadialisLongusRight = 'upper.right.extensorCarpiRadialisLongus';
const extensorCarpiUlnarisLeft = 'upper.left.extensorCarpiUlnaris';
const extensorCarpiUlnarisRight = 'upper.right.extensorCarpiUlnaris';
const extensorDigitorumLeft = 'upper.left.extensorDigitorum';
const extensorDigitorumRight = 'upper.right.extensorDigitorum';
const flexorCarpiRadialisLeft = 'upper.left.flexorCarpiRadialis';
const flexorCarpiRadialisRight = 'upper.right.flexorCarpiRadialis';
const flexorCarpiUlnarisLeft = 'upper.left.flexorCarpiUlnaris';
const flexorCarpiUlnarisRight = 'upper.right.flexorCarpiUlnaris';
const flexorDigitorumSuperficialisLeft = 'upper.left.flexorDigitorumSuperficialis';
const flexorDigitorumSuperficialisRight = 'upper.right.flexorDigitorumSuperficialis';
const gastrocnemiusCaputLateraleLeft = 'lower.left.gastrocnemiusCaputLaterale';
const gastrocnemiusCaputLateraleRight = 'lower.right.gastrocnemiusCaputLaterale';
const gastrocnemiusCaputMedialeLeft = 'lower.left.gastrocnemiusCaputMediale';
const gastrocnemiusCaputMedialeRight = 'lower.right.gastrocnemiusCaputMediale';
const iliopsoasLeft = 'back.left.iliopsoas';
const iliopsoasRight = 'back.right.iliopsoas';
const latissimusDorsiLeft = 'back.left.latissimusDorsi';
const latissimusDorsiRight = 'back.right.latissimusDorsi';
const palmarisLongusLeft = 'upper.left.palmarisLongus';
const palmarisLongusRight = 'upper.right.palmarisLongus';
const popliteusLeft = 'lower.left.popliteus';
const popliteusRight = 'lower.right.popliteus';
const pronatorTeresLeft = 'upper.left.pronatorTeres';
const pronatorTeresRight = 'upper.right.pronatorTeres';
const quadricepsFemorisLeft = 'lower.left.quadricepsFemoris';
const quadricepsFemorisRight = 'lower.right.quadricepsFemoris';
const semimembranosusLeft = 'lower.left.semimembranosus';
const semimembranosusRight = 'lower.right.semimembranosus';
const semitendinosusLeft = 'lower.left.semitendinosus';
const semitendinosusRight = 'lower.right.semitendinosus';
const serratusAnteriorLeft = 'pectoral.left.serratusAnterior';
const serratusAnteriorRight = 'pectoral.right.serratusAnterior';
const soleusLeft = 'lower.left.soleus';
const soleusRight = 'lower.right.soleus';
const tibialisAnteriorLeft = 'lower.left.tibialisAnterior';
const tibialisAnteriorRight = 'lower.right.tibialisAnterior';
const tricepsBrachiiLeft = 'back.left.tricepsBrachii';
const tricepsBrachiiRight = 'back.right.tricepsBrachii';
const tricepsSuraeCaputLateraleLeft = 'lower.left.tricepsSuraeCaputLaterale';
const tricepsSuraeCaputLateraleRight = 'lower.right.tricepsSuraeCaputLaterale';
const tricepsSuraeCaputMedialeLeft = 'lower.left.tricepsSuraeCaputMediale';
const tricepsSuraeCaputMedialeRight = 'lower.right.tricepsSuraeCaputMediale';

export const regions = ['back', 'lower', 'pectoral', 'upper'] as const;
export type RegionId = (typeof regions)[number];

export const muscles = {
  all: [
    adductorMagnusLeft,
    adductorMagnusRight,
    adductorPollicisLeft,
    adductorPollicisRight,
    bicepsBrachiiLeft,
    bicepsBrachiiRight,
    tricepsBrachiiLeft,
    tricepsBrachiiRight,
    brachioradialisLeft,
    brachioradialisRight,
    deltoideusClavicularPartLeft,
    deltoideusClavicularPartRight,
    extensorCarpiRadialisBrevisLeft,
    extensorCarpiRadialisBrevisRight,
    extensorCarpiRadialisLongusLeft,
    extensorCarpiRadialisLongusRight,
    extensorCarpiUlnarisLeft,
    extensorCarpiUlnarisRight,
    extensorDigitorumLeft,
    extensorDigitorumRight,
    flexorCarpiRadialisLeft,
    flexorCarpiRadialisRight,
    flexorCarpiUlnarisLeft,
    flexorCarpiUlnarisRight,
    flexorDigitorumSuperficialisLeft,
    flexorDigitorumSuperficialisRight,
    gastrocnemiusCaputLateraleLeft,
    gastrocnemiusCaputLateraleRight,
    gastrocnemiusCaputMedialeLeft,
    gastrocnemiusCaputMedialeRight,
    iliopsoasLeft,
    iliopsoasRight,
    latissimusDorsiLeft,
    latissimusDorsiRight,
    palmarisLongusLeft,
    palmarisLongusRight,
    popliteusLeft,
    popliteusRight,
    pronatorTeresLeft,
    pronatorTeresRight,
    quadricepsFemorisLeft,
    quadricepsFemorisRight,
    semimembranosusLeft,
    semimembranosusRight,
    semitendinosusLeft,
    semitendinosusRight,
    serratusAnteriorLeft,
    serratusAnteriorRight,
    soleusLeft,
    soleusRight,
    tibialisAnteriorLeft,
    tibialisAnteriorRight,
    tricepsSuraeCaputLateraleLeft,
    tricepsSuraeCaputLateraleRight,
    tricepsSuraeCaputMedialeLeft,
    tricepsSuraeCaputMedialeRight,
  ],
  'sidra-leg': [
    adductorMagnusLeft,
    adductorMagnusRight,
    extensorDigitorumLeft,
    extensorDigitorumRight,
    gastrocnemiusCaputLateraleLeft,
    gastrocnemiusCaputLateraleRight,
    gastrocnemiusCaputMedialeLeft,
    gastrocnemiusCaputMedialeRight,
    iliopsoasLeft,
    iliopsoasRight,
    popliteusLeft,
    popliteusRight,
    quadricepsFemorisLeft,
    quadricepsFemorisRight,
    semimembranosusLeft,
    semimembranosusRight,
    semitendinosusLeft,
    semitendinosusRight,
    soleusLeft,
    soleusRight,
    tibialisAnteriorLeft,
    tibialisAnteriorRight,
    tricepsSuraeCaputLateraleLeft,
    tricepsSuraeCaputLateraleRight,
    tricepsSuraeCaputMedialeLeft,
    tricepsSuraeCaputMedialeRight,
  ],
  'meissa-ot': [
    adductorPollicisLeft,
    adductorPollicisRight,
    bicepsBrachiiLeft,
    bicepsBrachiiRight,
    brachioradialisLeft,
    brachioradialisRight,
    deltoideusClavicularPartLeft,
    deltoideusClavicularPartRight,
    extensorCarpiRadialisBrevisLeft,
    extensorCarpiRadialisBrevisRight,
    extensorCarpiRadialisLongusLeft,
    extensorCarpiRadialisLongusRight,
    extensorCarpiUlnarisLeft,
    extensorCarpiUlnarisRight,
    extensorDigitorumLeft,
    extensorDigitorumRight,
    flexorCarpiRadialisLeft,
    flexorCarpiRadialisRight,
    flexorCarpiUlnarisLeft,
    flexorCarpiUlnarisRight,
    flexorDigitorumSuperficialisLeft,
    flexorDigitorumSuperficialisRight,
    latissimusDorsiLeft,
    latissimusDorsiRight,
    palmarisLongusLeft,
    palmarisLongusRight,
    pronatorTeresLeft,
    pronatorTeresRight,
    serratusAnteriorLeft,
    serratusAnteriorRight,
    tricepsBrachiiLeft,
    tricepsBrachiiRight,
  ],
  'stella-bio': [],
  unspecified: [],
} as const;

export type MuscleId = (typeof muscles)['all'][number];

export function getMuscleName(id: MuscleId) {
  return id.split('.').at(-1) ?? 'unknown';
}
