import { useEffect, useState } from 'react';
import { errorNotification } from 'helpers/handleNotifications';

import { useAppSelector } from './store';

export const useWifiNotification = () => {
  const [wasConnected, setWasConnected] = useState(false);
  const { connected } = useAppSelector(state => state.wifi);

  useEffect(() => {
    if (!connected) {
      if (wasConnected) {
        errorNotification('wifi.notifications.lostWifiConnection');
      }
    } else {
      setWasConnected(true);
    }
  }, [connected, wasConnected]);
};
