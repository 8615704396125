import { FunctionComponent, lazy, Suspense, useLayoutEffect, useMemo, useState } from 'react';
import { type ResponsiveValue, type ThemeTypings, Box, Image, ImageProps } from '@chakra-ui/react';
import placeholder200 from 'assets/images/muscles/placeholder200.png';
import { MuscleId, RegionId } from 'config/muscles';
import colors, { type Channel } from 'config/theme/colors';
import { logger } from 'helpers/logger';
import { capitalize } from 'helpers/string';

export type ImagesModuleKey = keyof typeof import('./images');

export type ExtractImageKey<T> = T extends `${string}Image` ? T : never;

export type ExtractElectrodesKey<T> = T extends `${string}Electrodes${'Ems' | 'Emg'}` ? T : never;

export interface MusclePath {
  type: 'region' | 'muscle';
  muscleId: RegionId | MuscleId;
}

export interface MuscleImageProps extends MusclePath, ImageProps {
  electrodes?: 'emg' | 'ems';
  channel?: Channel;
}

const SvgFallbackComponent: FunctionComponent<React.SVGProps<SVGSVGElement>> = () => <svg></svg>;

export function useMuscleImageSources({
  type,
  muscle,
  side,
}: {
  type: 'region' | 'muscle';
  muscle: string;
  side: 'left' | 'right';
}) {
  const [image, setImage] = useState(placeholder200);

  const importId = useMemo(() => `${capitalize(type)}${capitalize(muscle)}${capitalize(side)}`, [muscle, side, type]);

  useLayoutEffect(() => {
    import('./images')
      .then((module: Record<ExtractImageKey<ImagesModuleKey>, string>) => {
        if (module[`${importId}Image` as keyof typeof module]) {
          setImage(module[`${importId}Image` as keyof typeof module]);
        } else {
          logger.warn('useMuscleImageSources', `Image: ${importId} does not exists`);
        }
      })
      .catch(e => logger.warn('useMuscleImageSources', `Failed loading image: ${importId}`, e));
  }, [importId]);

  const ElectrodesEms = useMemo(
    () =>
      lazy(() =>
        import('./images').then(
          (
            module: Record<ExtractElectrodesKey<ImagesModuleKey>, FunctionComponent<React.SVGProps<SVGSVGElement>>>,
          ) => ({
            default: module[`${importId}ElectrodesEms` as keyof typeof module]
              ? module[`${importId}ElectrodesEms` as keyof typeof module]
              : SvgFallbackComponent,
          }),
        ),
      ),
    [importId],
  );

  const ElectrodesEmg = useMemo(
    () =>
      lazy(() =>
        import('./images').then(
          (
            module: Record<ExtractElectrodesKey<ImagesModuleKey>, FunctionComponent<React.SVGProps<SVGSVGElement>>>,
          ) => ({
            default: module[`${importId}ElectrodesEmg` as keyof typeof module]
              ? module[`${importId}ElectrodesEmg` as keyof typeof module]
              : SvgFallbackComponent,
          }),
        ),
      ),
    [importId],
  );

  return {
    image,
    ElectrodesEms,
    ElectrodesEmg,
  } as const;
}

export function MuscleImage({ type, muscleId, electrodes, channel, boxSize = 48, ...props }: MuscleImageProps) {
  const sources = useMuscleImageSources({
    type,
    muscle: muscleId.split('.').at(-1) ?? 'lower.left.quadricepsFemoris',
    side: (muscleId.split('.')[1] ?? 'left') as 'left' | 'right',
  });
  const channelColor: ResponsiveValue<ThemeTypings['colors']> = channel ? colors[`channel.${channel}`] : 'black';

  return (
    <Box position="relative" h={boxSize} w={boxSize}>
      <Image boxSize={boxSize} src={sources.image} alt="muscle" {...props} />
      {electrodes === 'emg' ? (
        <Suspense>
          <Box position="absolute" top={0} left={0} h={boxSize} w={boxSize}>
            <sources.ElectrodesEmg color={channelColor} height="100%" width="100%" />
          </Box>
        </Suspense>
      ) : null}
      {electrodes === 'ems' ? (
        <Suspense>
          <Box position="absolute" top={0} left={0} h={boxSize} w={boxSize}>
            <sources.ElectrodesEms color={channelColor} height="100%" width="100%" />
          </Box>
        </Suspense>
      ) : null}
    </Box>
  );
}
