import { useContext, useMemo } from 'react';
import { Exercise } from 'libs/exo-session-manager/core/exercises/Exercise';

import { CAMInstanceContext } from '../providers/CAMInstanceContext';
import { CPMElectrostimInstanceContext } from '../providers/CPMElectrostimInstanceContext';
import { CPMInstanceContext } from '../providers/CPMInstanceContext';

export const useExercise = (): Exercise => {
  const cpm = useContext(CPMInstanceContext);
  const cpmEms = useContext(CPMElectrostimInstanceContext);
  const cam = useContext(CAMInstanceContext);

  return useMemo(() => {
    if (cpm) {
      return cpm;
    }

    if (cpmEms) {
      return cpmEms;
    }

    if (cam) {
      return cam;
    }

    // TODO: add missing exercises type (EMS, EMG) if required

    throw new Error('useExercise must be used within a exercise provider');
  }, [cam, cpm, cpmEms]);
};
