import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';
import { DeviceType } from '../global/DeviceManager';

import { basingSummaryFlow } from './basingSummaryFlow';
import { meissaOTCalibrationFlow } from './meissaOTCalibrationFlow';
import { sidraLegCalibrationFlow } from './sidraLegCalibrationFlow';
import { stellaBioCalibrationFlow } from './stellaBioCalibrationFlow';
import {
  CalibrationFlowDefinitionState,
  CalibrationFlowDefinitionStates,
  CalibrationProgramFlowDefinition,
  isMeissaOTTrainingType,
  isSidraLegTrainingType,
  isStellaBioTrainingType,
  MeissaOTTrainingType,
  SidraLegTrainingType,
  SidraLegTrainingTypeWithGame,
  StellaBioTrainingType,
} from './types';
export function getDeviceTrainingCalibrationFlow(
  deviceType: 'meissa-ot',
  trainingType: MeissaOTTrainingType,
): (typeof meissaOTCalibrationFlow)[MeissaOTTrainingType];
export function getDeviceTrainingCalibrationFlow(
  deviceType: 'stella-bio',
  trainingType: StellaBioTrainingType,
): (typeof stellaBioCalibrationFlow)[StellaBioTrainingType];
export function getDeviceTrainingCalibrationFlow(
  deviceType: 'sidra-leg',
  trainingType: SidraLegTrainingType | SidraLegTrainingTypeWithGame,
): (typeof sidraLegCalibrationFlow)[SidraLegTrainingType];
export function getDeviceTrainingCalibrationFlow(
  deviceType: DeviceType,
  trainingType: StellaBioTrainingType | SidraLegTrainingType,
): CalibrationProgramFlowDefinition;

export function getDeviceTrainingCalibrationFlow(
  deviceType: DeviceType,
  trainingType: StellaBioTrainingType | SidraLegTrainingType | SidraLegTrainingTypeWithGame | MeissaOTTrainingType,
): CalibrationProgramFlowDefinition {
  if (!trainingType) {
    throw new Error('Training type must be specified');
  }

  let flow: CalibrationProgramFlowDefinition;

  switch (deviceType) {
    case 'stella-bio':
      if (!isStellaBioTrainingType(trainingType)) {
        throw new Error('Stella BIO does not support this type of training');
      }

      flow = stellaBioCalibrationFlow[trainingType];
      break;
    case 'sidra-leg':
      if (isSidraLegTrainingType(trainingType)) {
        flow = sidraLegCalibrationFlow[trainingType];
      } else {
        throw new Error(`Sidra LEG does not support this type of training: ${trainingType}`);
      }
      break;
    case 'meissa-ot':
      if (isMeissaOTTrainingType(trainingType)) {
        flow = meissaOTCalibrationFlow[trainingType];
      } else {
        throw new Error(`Meissa OT does not support this type of training: ${trainingType}`);
      }
      break;
    default:
      throw new Error('Device type must be specified');
  }
  return flow;
}

export function addSummaryToFlow(flow: CalibrationProgramFlowDefinition) {
  flow.states = {
    ...basingSummaryFlow(null, flow.initial, 1),
    ...shiftCalibrationFlowStates('basing-summary', null, flow.states, flow.initial, 2),
  };
  flow.initial = 'basing-summary';
}

export function shiftCalibrationFlowStates(
  prevStep: CalibrationFlowStateIdentifier | null,
  nextStep: CalibrationFlowStateIdentifier | null,
  states: CalibrationFlowDefinitionStates,
  initial: CalibrationFlowStateIdentifier,
  stepIndex: number,
) {
  let stepId = initial;

  while (stepId) {
    const step = states[stepId] as CalibrationFlowDefinitionState;

    if (stepId === initial) {
      step.prev = prevStep;
    }
    if (step.next === null) {
      step.next = nextStep;
    }

    if (step) {
      step.stepIndex = stepIndex++;
      if (!step.next) {
        break;
      }
      stepId = step.next;
    } else {
      throw new Error(`Flow points to step ${stepId} which not exists in theflow. Invalid flow`);
    }
  }

  return states;
}
