/* eslint-disable simple-import-sort/exports */
import { ExerciseActionTracker } from './global/ExerciseActionTracker';
import { HistoryActionTracker } from './global/HistoryActionTracker';

export * from './global/ExerciseSelector';
export * from './global/DeviceManager';
export * from './calibrations';
export * from './common/alteredRangeForMotor';
export * from './common/calculations';
export * from './common/CalibrationFlow';
export * from './common/EMGSignal';
export * from './common/EMSCalibration';
export * from './common/EMSFeatures';
export * from './common/SignalRecorderController';
export * from './constants';
export * from './definitions/stella-bio/emg-programs';
export * from './definitions/stella-bio/ems-programs';
export * from './exercises/CAMExercise';
export * from './exercises/CPMElectrostimExercise';
export * from './exercises/CPMExercise';
export * from './exercises/EMGExercise';
export * from './exercises/FinishedReason';
export * from './global/ExerciseActionTracker';
export * from './global/HistoryActionTracker';
export * from './settings/CAMSettings';
export * from './settings/CPMSettings';
export * from './settings/EMSSettings';
export * from './settings/SettingsBuilder';
export * from './settings/SettingsTemplates';
export * from './types';
export * from './types/CAMBasingData';
export * from './types/CAMExerciseData';
export * from './types/CAMProgramData';
export * from './types/GeneratedCAMProgramDefinition';
export * from './types/GeneratedCPMProgramDefinition';
export * from './types/GeneratedElectrostimProgramDefinition';
export * from './types/GeneratedExerciseDefinition';
export * from './types/GeneratedProgramDefinition';

export const historyActionTracker = new HistoryActionTracker();
export const exerciseActionTracker = new ExerciseActionTracker();
