import { FC, ReactElement, useState } from 'react';
import { Container, Grid, useMediaQuery } from '@chakra-ui/react';
import mediaQuerySizes from 'config/theme/mediaQuerySizes';

import { GridMobile } from './GridMobile';
import { GridMobileIcon } from './GridMobileIcon';

interface Props {
  children: ReactElement;
  text: string;
}

export const FiltersWrapper: FC<Props> = ({ children }: Props) => {
  const [lessThanLg] = useMediaQuery(mediaQuerySizes.lessThanLg);
  const [showFilter, setShowFilter] = useState(false);

  return (
    <Container variant="filterBox">
      <Grid templateColumns="repeat(2, 1fr)">
        {lessThanLg && <GridMobileIcon onClick={() => setShowFilter(!showFilter)} />}
      </Grid>
      {(!lessThanLg || showFilter) && <GridMobile>{children}</GridMobile>}
    </Container>
  );
};
