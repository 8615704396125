import { FC, ReactNode } from 'react';
import { Grid } from '@chakra-ui/react';

interface GridMobile {
  children: ReactNode;
}

export const GridMobile: FC<GridMobile> = ({ children }: GridMobile) => (
  <Grid templateColumns={['1', null, null, 'repeat(2, 1fr)']} gap="4" alignItems="flex-end">
    {children}
  </Grid>
);
