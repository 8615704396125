import React, { FC, useEffect, useMemo, useState } from 'react';
import { Grid } from '@chakra-ui/react';
import theme from 'config/theme';
import { __ } from 'helpers/i18n';
import { useTrainingList } from 'hooks/lists/useTrainingList';
import { useAppSelector } from 'hooks/store';
import { useDevice } from 'libs/exo-session-manager/react';
import { TrainingItem } from 'views/specialist/patients/+patientId/trainings/_containers/TrainingList/TrainingItem';

import { InfiniteScrollBox } from 'components/common/InfiniteScrollBox';
import TrainingSkeletonCard from 'components/skeletons/trainings/TrainingSkeletonCard';

interface TrainingListProps {
  favorite?: boolean;
}

export const TrainingList: FC<TrainingListProps> = ({ favorite }) => {
  const { trainingList, isEndOfPages, inProgress, nextPage, currentFilters } = useAppSelector(
    state => state.trainingList,
  );
  const { loadMoreTrainings } = useTrainingList();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    !inProgress && setIsLoading(inProgress);
  }, [inProgress]);

  const { selectedDevice } = useDevice();

  // TODO: currently since there is no endpoint to fetch trainings by device type, we fetch all trainings and filter them by device type in exercises if included
  const filteredByDeviceTrainingList = useMemo(
    () =>
      trainingList.filter(training =>
        Object.values(training.exercises).some(exercise => exercise.deviceType === selectedDevice?.type),
      ),
    [selectedDevice?.type, trainingList],
  );
  const handleLoadMore = () => {
    if (!isEndOfPages) {
      loadMoreTrainings(nextPage, favorite);
    }
  };

  const filteredByQueryString = useMemo(() => {
    const searchList = filteredByDeviceTrainingList.map(item => {
      return {
        id: item.id,
        searchString: __(`training.headings.title.${item.name}`).toLowerCase(),
      };
    });
    const queryWords = currentFilters?.name?.toLowerCase().split(' ');
    return filteredByDeviceTrainingList.filter(item => {
      const currentSearchItem = searchList.find(v => v.id === item.id);
      for (const word of queryWords ?? []) {
        if (word && currentSearchItem && currentSearchItem?.searchString.indexOf(word) < 0) {
          return false;
        }
      }
      return true;
    });
  }, [currentFilters.name, filteredByDeviceTrainingList]);

  const TrainingListSkeleton = () => {
    return (
      <Grid
        templateColumns={['repeat(1, 2fr)', null, null, 'repeat(2, 2fr)']}
        gap="2"
        mt="2"
        maxH={`calc(100vh - ${theme.sizes['navbarHeight']})`}
        overflowY="hidden"
      >
        {[...Array(10)].map((_, i) => (
          <TrainingSkeletonCard key={i} />
        ))}
      </Grid>
    );
  };
  return (
    <>
      {isLoading ? (
        <TrainingListSkeleton />
      ) : (
        <InfiniteScrollBox
          CustomLoadMoreElement={() =>
            nextPage === 0 ? (
              <TrainingListSkeleton />
            ) : inProgress ? (
              <Grid templateColumns="1fr 1fr" mt={2} gap={2}>
                <TrainingSkeletonCard />
                <TrainingSkeletonCard />
              </Grid>
            ) : null
          }
          loadMore={handleLoadMore}
          hasMore={!isEndOfPages}
          loading={inProgress}
          px="0"
          py="0"
        >
          <Grid
            templateColumns={['repeat(1, 2fr)', null, null, 'repeat(2, 2fr)']}
            gap="2"
            mt="2"
            maxH={`calc(100vh - ${theme.sizes['navbarHeight']})`}
            overflowY="visible"
          >
            {filteredByQueryString.map(item => (
              <TrainingItem key={item.id} trainingTemplate={item} />
            ))}
          </Grid>
        </InfiniteScrollBox>
      )}
    </>
  );
};
