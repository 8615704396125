import { DeviceType, SensorsName } from 'libs/exo-session-manager/core';

export const defaultDelay = 300;
export const leftThighMinSize = 30;
export const rightThighMinSize = 49;
export const thighUnit = 'cm';

export const maxDeviceRangeKnee = 120;
export const minDeviceRangeKnee = -10;

export const maxDeviceRangeAnkle = 25;
export const minDeviceRangeAnkle = -45;

export const numberSpeedMeasurements = 3;

// Angle measurement tolerance for devices. Added in the task EGZOTech/exo-clinic#811
export const deviceTolerances: Record<DeviceType, number> = {
  'sidra-leg': 0.5,
  'meissa-ot': 2,
  'stella-bio': 0,
  unspecified: 0,
};

export const rangeTolerances: Partial<Record<DeviceType, { min: number; portion: number }>> = {
  'sidra-leg': { min: 5, portion: 0.25 },
  'meissa-ot': { min: deviceTolerances['meissa-ot'], portion: 0.25 },
};

// FIXME: this probably should be changed
export const sensorsRange = {
  knee: { max: 75, min: -75 },
  toes: { max: 100, min: -100 },
  heel: { max: 100, min: -100 },
  extension: { max: 100, min: 0 },
  torque: { max: 50, min: -50 },
  horizontal: { max: 90, min: -90 }, // meissa horizontal Angle
  vertical: { max: 90, min: 0 }, // meissa vertical Angle
};

export const parameterValidationsConfig = [
  {
    parameter: 'startActiveMovementForce',
    dependsOn: 'endActiveMovementForce',
    validationNameFn: 'lessThan',
  },
  {
    parameter: 'endActiveMovementForce',
    dependsOn: 'startActiveMovementForce',
    validationNameFn: 'greaterThan',
  },
  {
    parameter: 'activeMovementDirection',
    dependsOn: 'forceSource',
    validationNameFn: 'excludeBothMovementDirection',
  },
];

export const currentVersionOfAdditionalData = 1;

export const defaultSensorNameByDevice: Partial<Record<DeviceType, SensorsName>> = {
  'sidra-leg': 'toes',
  'meissa-ot': 'torque',
};

export const unavailableExercisesByDevice: Partial<Record<DeviceType, string[]>> = {
  'sidra-leg': ['cpm_force_classic'],
  'meissa-ot': [],
};

// Array of parameter IDs that can be multi-line
export const multiLineParametersIdArray = ['normal', 'reverse', 'toMin', 'toMax'];

// Minimum length of parameter text when it stays multiline
export const multiLineParametersMinLength = 18;
