import {
  CAMComprehensiveParameterId,
  CAMPhasicParameterId,
  CPMComprehensiveParameterId,
  CPMPhasicParameterId,
  EMSComprehensiveParameterId,
  EMSPhasicParameterId,
  GamePhasicParameterId,
} from 'libs/exo-session-manager/core/settings/SettingsBuilder';

export type SettingsParameterType = 'cpm' | 'ems' | 'cam' | 'game';

export const parameterCategories = [
  'ems',
  'ems-phase-0',
  'ems-phase-1',
  'general',
  'cpm-device',
  'cam-device',
  'game',
  'ems-current',
  'ranges',
] as const;
export type ParameterCategory = (typeof parameterCategories)[number];

// TODO maybe split categories into level 1 / 2 etc.
export type ParameterCategoryPath = `${ParameterCategory}` | `${ParameterCategory}.${ParameterCategory}` | null;
export type SettingsCustomParameterId = 'device-ranges' | 'ems-current-phase-0' | 'ems-current-phase-1';

export type SettingsParameterId =
  | `cpm.${CPMComprehensiveParameterId}`
  | `cpm.${number}.${CPMPhasicParameterId}`
  | `cam.${CAMComprehensiveParameterId}`
  | `cam.${number}.${CAMPhasicParameterId}`
  | `ems.${EMSComprehensiveParameterId}`
  | `ems.${number}.${EMSPhasicParameterId}`
  | `game.${number}.${GamePhasicParameterId}`
  | SettingsCustomParameterId;

export type ParameterCategories = (typeof parameterCategories)[number];

export type SettingsTreeElement =
  | {
      type: 'category';
      categoryId: ParameterCategories;
      children: SettingsTreeElement[];
      linkedTo?: ParameterCategoryPath;
    }
  | {
      type: 'parameter';
      parameterId: SettingsParameterId;
    }
  | {
      type: 'custom-parameter';
      parameterId: SettingsCustomParameterId;
    };

export const generalCategoryTemplate: SettingsTreeElement[] = [
  {
    type: 'category',
    categoryId: 'general',
    children: [
      {
        type: 'parameter',
        parameterId: 'cpm.0.time',
      },
      {
        type: 'parameter',
        parameterId: 'cam.0.time',
      },
      {
        type: 'parameter',
        parameterId: 'cpm.0.repetitions',
      },
      {
        type: 'parameter',
        parameterId: 'cam.0.repetitions',
      },
      {
        type: 'parameter',
        parameterId: 'cpm.triggeringType',
      },
      { type: 'parameter', parameterId: 'cpm.0.movementType' },
      { type: 'parameter', parameterId: 'cpm.0.triggeringMethod' },
    ],
  },
];

export const camCategoryTemplate: SettingsTreeElement[] = [
  {
    type: 'category',
    categoryId: 'cam-device',
    children: [
      {
        type: 'parameter',
        parameterId: 'cam.0.activeMovementDirection',
      },
      {
        type: 'parameter',
        parameterId: 'cam.0.forceSource',
      },
      {
        type: 'parameter',
        parameterId: 'cam.maxTime',
      },
      {
        type: 'parameter',
        parameterId: 'cam.0.maxSpeed',
      },
      {
        type: 'parameter',
        parameterId: 'cam.0.deadband',
      },
      {
        type: 'parameter',
        parameterId: 'cam.0.startActiveMovementForce',
      },
      {
        type: 'parameter',
        parameterId: 'cam.0.endActiveMovementForce',
      },
    ],
  },
];

export const emsPhase0Template: SettingsTreeElement[] = [
  {
    type: 'parameter',
    parameterId: 'ems.0.pulseFrequency',
  },
  {
    type: 'parameter',
    parameterId: 'ems.0.pulseWidth',
  },
  {
    type: 'parameter',
    parameterId: 'ems.0.riseTime',
  },
  {
    type: 'parameter',
    parameterId: 'ems.0.fallTime',
  },
  {
    type: 'parameter',
    parameterId: 'ems.0.plateauTime',
  },
  {
    type: 'parameter',
    parameterId: 'ems.0.pauseTime',
  },
  {
    type: 'parameter',
    parameterId: 'ems.0.runTime',
  },
];

export const emsPhase1Template: SettingsTreeElement[] = [
  {
    type: 'parameter',
    parameterId: 'ems.1.pulseFrequency',
  },
  {
    type: 'parameter',
    parameterId: 'ems.1.pulseWidth',
  },
  {
    type: 'parameter',
    parameterId: 'ems.1.riseTime',
  },
  {
    type: 'parameter',
    parameterId: 'ems.1.fallTime',
  },
  {
    type: 'parameter',
    parameterId: 'ems.1.plateauTime',
  },
  {
    type: 'parameter',
    parameterId: 'ems.1.pauseTime',
  },
  {
    type: 'parameter',
    parameterId: 'ems.1.runTime',
  },
];

export const cpmCategoryTemplate: SettingsTreeElement[] = [
  {
    type: 'category',
    categoryId: 'cpm-device',
    children: [
      {
        type: 'parameter',
        parameterId: 'cpm.maxBackwardForceLimit',
      },
      { type: 'parameter', parameterId: 'cpm.0.forceTriggerSource' },
      { type: 'parameter', parameterId: 'cpm.0.speed' },
      { type: 'parameter', parameterId: 'cpm.0.pauseTimeInROMMin' },
      { type: 'parameter', parameterId: 'cpm.0.pauseTimeInROMMax' },
    ],
  },
];

export const emsUniDirectionalCategoryTemplate: SettingsTreeElement[] = [
  {
    type: 'category',
    categoryId: 'ems',
    children: [...emsPhase0Template],
    linkedTo: 'ems-current',
  },
];

export const emsBiDirectionalCategoryTemplate: SettingsTreeElement[] = [
  {
    type: 'category',
    categoryId: 'ems',
    children: [
      {
        type: 'category',
        categoryId: 'ems-phase-0',
        children: [...emsPhase0Template],
        linkedTo: 'ems-current.ems-phase-0',
      },
      {
        type: 'category',
        categoryId: 'ems-phase-1',
        children: [...emsPhase1Template],
        linkedTo: 'ems-current.ems-phase-1',
      },
    ],
  },
];

export const gameCategoryTemplate: SettingsTreeElement[] = [
  {
    type: 'category',
    categoryId: 'game',
    children: [
      {
        type: 'parameter',
        parameterId: 'game.0.speed',
      },
      {
        type: 'parameter',
        parameterId: 'game.0.routeWidth',
      },
      {
        type: 'parameter',
        parameterId: 'game.0.oponent',
      },
      {
        type: 'parameter',
        parameterId: 'game.0.blockers',
      },
      {
        type: 'parameter',
        parameterId: 'game.0.gameBackground',
      },
      {
        type: 'parameter',
        parameterId: 'game.0.carColor',
      },
      {
        type: 'parameter',
        parameterId: 'game.0.gameSteeringMode',
      },
    ],
  },
];

const exerciseParameterTreeAddonsUniDirectionalEms: SettingsTreeElement[] = [
  {
    type: 'category',
    categoryId: 'ems-current',
    children: [
      {
        type: 'custom-parameter',
        parameterId: 'ems-current-phase-0',
      },
    ],
  },
];

const exerciseParameterTreeAddonsBiDirectionalEms: SettingsTreeElement[] = [
  {
    type: 'category',
    categoryId: 'ems-current',
    children: [
      {
        type: 'category',
        categoryId: 'ems-phase-0',
        children: [
          {
            type: 'custom-parameter',
            parameterId: 'ems-current-phase-0',
          },
        ],
      },
      {
        type: 'category',
        categoryId: 'ems-phase-1',
        children: [
          {
            type: 'custom-parameter',
            parameterId: 'ems-current-phase-1',
          },
        ],
      },
    ],
  },
];

const deviceRangeTemplate: SettingsTreeElement[] = [
  {
    type: 'category',
    categoryId: 'ranges',
    children: [
      {
        type: 'custom-parameter',
        parameterId: 'device-ranges',
      },
    ],
  },
];

export type TriggeringType = 'uni-directional' | 'bi-directional';
export type SettingsParametersPlace = 'basing' | 'exercise';
export type BaseSettingsTemplates = Record<TriggeringType, SettingsTreeElement[]>;

export const basingParameterTemplateUniDirectionalEMS: SettingsTreeElement[] = [
  ...generalCategoryTemplate,
  ...cpmCategoryTemplate,
  ...camCategoryTemplate,
  ...emsUniDirectionalCategoryTemplate,
  ...gameCategoryTemplate,
];

export const basingParameterTemplateBiDirectionalEMS: SettingsTreeElement[] = [
  ...generalCategoryTemplate,
  ...cpmCategoryTemplate,
  ...camCategoryTemplate,
  ...emsBiDirectionalCategoryTemplate,
  ...gameCategoryTemplate,
];

export const exerciseParameterTemplateUniDirectionalEMS: SettingsTreeElement[] = [
  ...generalCategoryTemplate,
  ...cpmCategoryTemplate,
  ...camCategoryTemplate,
  ...emsUniDirectionalCategoryTemplate,
  ...exerciseParameterTreeAddonsUniDirectionalEms,
  ...deviceRangeTemplate,
  ...gameCategoryTemplate,
];

export const exerciseParameterTemplateBiDirectionalEMS: SettingsTreeElement[] = [
  ...generalCategoryTemplate,
  ...cpmCategoryTemplate,
  ...camCategoryTemplate,
  ...emsBiDirectionalCategoryTemplate,
  ...exerciseParameterTreeAddonsBiDirectionalEms,
  ...deviceRangeTemplate,
  ...gameCategoryTemplate,
];
