import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';

import { CalibrationFlowDefinitionStates } from './types';

export function setParametersFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
): CalibrationFlowDefinitionStates {
  return {
    'basing-settings': {
      prev,
      next,
      stepIndex,
      disableAlerts: ['cableDetached', 'disconnectedElectrode', 'extensionDetached'],
    },
  } as const;
}
