import { calculateSensitivity } from 'helpers/units';
import { GeneratedCAMGameExerciseDefinition } from 'libs/exo-session-manager/core/types/GeneratedExerciseDefinition';

export const getPredefinedMeissaOTGameCosmicMissionExercises = (): {
  [key: string]: GeneratedCAMGameExerciseDefinition;
} => ({
  game_cosmic_mission_cam_classic_meissa: {
    type: 'cam-game',
    calibrationFlow: 'cam-isokinetic/meissa/cosmic-mission',
    tags: ['cosmic-mission'],
    cam: {
      main: {
        program: {
          phases: [
            {
              time: 10 * 60,
              maxSpeed: 90,
              activeMovementDirection: 'both',
              coupling: {
                force: {
                  extension: {
                    sensitivity: calculateSensitivity(1, 1000),
                    deadband: 2,
                  },
                  torque: {
                    sensitivity: calculateSensitivity(1, 1000),
                    deadband: 2,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'torque',
                unit: 'none',
                values: ['torque'],
                blockAfterStart: true,
              },
              maxSpeed: {
                default: 90,
                unit: 'deg/s',
                values: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
    },
    game: {
      id: 'cosmic_mission',
      program: {
        phases: [
          {
            speed: 10,
            routeWidth: 1,
            oponent: false,
            gameSteeringMode: 'normalControl',
          },
        ],
      },
      parameters: {
        phases: [
          {
            speed: {
              default: 100,
              unit: '%',
              values: [50, 75, 100, 125, 150],
              blockAfterStart: false,
            },
            routeWidth: {
              default: 1,
              unit: 'number',
              values: [0.5, 0.75, 1, 1.25, 1.5],
              blockAfterStart: false,
            },
            oponent: {
              default: 'no',
              unit: 'none',
              values: ['yes', 'no'],
              blockAfterStart: false,
            },
            gameSteeringMode: {
              default: 'normalControl',
              unit: 'none',
              values: ['normalControl', 'invertControl'],
              blockAfterStart: false,
            },
          },
        ],
      },
    },
  },
});
