import { useEffect } from 'react';
import { useTrainingList } from 'hooks/lists/useTrainingList';

import { TrainingFilter } from '../_containers/TrainingFilter';
import { TrainingList } from '../_containers/TrainingList';

const FavoriteTraining = () => {
  const { loadTrainings, setFavoriteCategory } = useTrainingList();
  useEffect(() => {
    loadTrainings({ isFavoriteTraining: true });
    setFavoriteCategory(true);
    // FIXME: for older code, lint warnings have been disabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TrainingFilter />
      <TrainingList favorite />
    </>
  );
};

export default FavoriteTraining;
