import { DeepReadonly } from '@egzotech/exo-session';
import { EMGProgram, EMGProgramData } from 'libs/exo-session-manager/core';
import { createContext } from 'use-context-selector';

export interface EMGProgramActions {
  /**
   * Play EMG program: device starts emg monitoring
   */
  play: () => void;
  /**
   * Pause EMG program: device pauses emg monitoring
   */
  pause: () => void;
  /**
   * End EMG program: device stops emg monitoring
   */
  end: () => void;
}

export interface EMGContext {
  program: {
    /**
     * Returns predefined EMG program parameters
     */
    definition: DeepReadonly<EMGProgram>;
  } & EMGProgramData &
    EMGProgramActions;
}

export const initialEMGContext = {
  program: null,
  emgSignal: null,
};

const EMGContext = createContext<EMGContext | typeof initialEMGContext>(initialEMGContext);

export default EMGContext;
