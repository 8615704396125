import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Image, Skeleton, Text, Tr } from '@chakra-ui/react';
import chartIcon from 'assets/images/chart-line.svg';
import repeatIcon from 'assets/images/repeat-variant.svg';
import RepeatTrainingButton from 'containers/buttons/RepeatTrainingButton';
import { getImageAsset } from 'helpers/assets';
import { formatDate } from 'helpers/formatDate';
import { formatInterval } from 'helpers/formatInterval';
import { DeviceType } from 'libs/exo-session-manager/core';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';
import { Exercise } from 'types';
import { Months } from 'types/date';

import { RoundButton } from 'components/buttons/RoundButton';
import { AppSpinner } from 'components/common/AppSpinner';
import { TranslateText } from 'components/texts/TranslateText';

import { TableItem } from './TableItem';

interface RecentTrainingItemProps {
  training: ExoClinicBackendOpenApiSchemas['TrainingResponseDto'];
  deviceType: DeviceType;
}

export const RecentTrainingItem: FC<RecentTrainingItemProps> = ({ training, deviceType }: RecentTrainingItemProps) => {
  const { trainingTemplate, completeTime } = training;
  const { patientId } = useParams();
  const dateTime = new Date(completeTime ?? '');
  const [loading, setLoading] = useState(false);

  const setLoadingCallback = useCallback((val: boolean) => {
    setLoading(val);
  }, []);

  const isDisabled = useMemo(
    () =>
      Object.values(training.trainingTemplate.exercises as unknown as Record<number, Exercise>).some(
        exercise => exercise.deviceType !== deviceType,
      ),
    [deviceType, training.trainingTemplate.exercises],
  );

  const getCompleteTime = () => (training.completeTime ? formatDate(training.completeTime, 'time') : '');

  const getDuration = () => {
    return formatInterval((training.duration ?? 0) * 1000);
  };

  const duration = getDuration();

  return (
    <Tr maxW="full">
      <TableItem>
        {trainingTemplate.image ? (
          <Flex justify="center" pt="3" pl="4">
            <Image
              src={getImageAsset(trainingTemplate.image)}
              h={{ base: '20', '2xl': '28' }}
              minW={{ base: '16', '2xl': '20' }}
              maxW={{ base: '16', '2xl': '20' }}
              alt=""
              mx="1"
            />
          </Flex>
        ) : null}
      </TableItem>
      <TableItem textAlign width="400px">
        <TranslateText
          text={`training.headings.title.${trainingTemplate.name}`}
          variant="openSans20"
          whiteSpace="normal"
          overflowWrap="anywhere"
        />
      </TableItem>
      <TableItem>
        <Text
          as="span"
          variant="openSans20"
          display="flex"
          justifyContent="center"
          alignItems="center"
          whiteSpace="pre"
        >
          {dateTime.getDate()}
          <Text>{` `}</Text>
          <TranslateText
            text={`calendar.${Months[dateTime.getMonth()]}`}
          >{` ${dateTime.getFullYear()} ${getCompleteTime()}`}</TranslateText>
        </Text>
      </TableItem>
      <TableItem>
        {duration ? (
          <Text variant="openSans20">{duration}</Text>
        ) : (
          <Skeleton startColor="skeletonStartColor" endColor="skeletonEndColor" height={9} width="100%" />
        )}
      </TableItem>
      <TableItem>
        <RoundButton
          type="light"
          size="11"
          icon={<Image src={chartIcon} width="5" height="5" />}
          mr="0"
          redirect={`/specialist/patients/${patientId}/report/${training.id}`}
        />
      </TableItem>
      <TableItem>
        <RepeatTrainingButton
          patientId={patientId ?? ''}
          training={training}
          type="light"
          size={{ base: '14', '2xl': '16' }}
          icon={loading ? <AppSpinner /> : <Image src={repeatIcon} width="6" height="6" />}
          mr="0"
          setLoading={setLoadingCallback}
          isDisabled={isDisabled}
        />
      </TableItem>
    </Tr>
  );
};
