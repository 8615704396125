import { DeepReadonly, EventBus, ExoSession } from '@egzotech/exo-session';
import { Logger } from '@egzotech/universal-logger-js';

import EMGSignal from '../common/EMGSignal';
import { EMGProgram } from '../definitions/stella-bio/emg-programs';
import { EMGProgramData } from '../types';

export type EMGProgramEvents = {
  onEmgInit: () => void;
  onEmgDestroy: () => void;
  onEmgRunningChange: (payload: EMGProgramData['running']) => void;
  onEmgDataChange: (payload: EMGProgramData['currentData']) => void;
  onEmgDurationChange: (payload: EMGProgramData['duration']) => void;
  onEmgEnd: () => void;
};

export class EMGExercise {
  private _programData: EMGProgramData;
  private _emgSignal: EMGSignal;

  static readonly logger = Logger.getInstance('EMGExercise');

  readonly events: EventBus<EMGProgramEvents> = new EventBus();

  constructor(private readonly programDefinition: DeepReadonly<EMGProgram>, private readonly session: ExoSession) {
    this._programData = {
      active: false,
      running: false,
      ended: false,
      totalDuration: 0,
      duration: 0,
      currentData: [],
    };

    const steps = programDefinition.steps;
    for (let item = 0; item < steps.length; item++) {
      this._programData.totalDuration +=
        (steps[item].initialRelaxation + steps[item].repetitions * (steps[item].workTime + steps[item].restTime)) *
        1000;
    }
    const emgProgramThresholdFactor = this.programDefinition.steps[0].threshold / 100;
    this._emgSignal = new EMGSignal(session, emgProgramThresholdFactor);
  }

  get emgSignal() {
    return this._emgSignal;
  }

  get programData() {
    return this._programData;
  }

  init() {
    if (this._programData.active) {
      EMGExercise.logger.debug('start', 'Program is already started');
      return;
    }
    this._emgSignal.initialize({});
    this._programData.active = true;
    this._programData.ended = false;
    EventBus.raise(this.events, 'onEmgInit');
  }

  destroy() {
    this.end();
    this._emgSignal.dispose();
    this._programData.running = false;
    this._programData.active = false;
    EventBus.raise(this.events, 'onEmgDestroy');
  }

  play() {
    if (!this._programData.active) {
      EMGExercise.logger.debug('play', 'Cannot play program that is ended or not yet started');
      return;
    }
    if (this._programData.ended) {
      throw new Error('Cannot play program that is ended');
    }

    this._programData.running = true;
    this.enableEMGMonitoring();
    EventBus.raise(this.events, 'onEmgRunningChange', this._programData.running);
  }

  pause() {
    if (!this._programData.active) {
      EMGExercise.logger.debug('pause', 'Cannot pause program that is ended or not yet started');
      return;
    }

    this._programData.running = false;
    this.disableEMGMonitoring();
    EventBus.raise(this.events, 'onEmgRunningChange', this._programData.running);
  }

  end() {
    if (!this._programData.active) {
      EMGExercise.logger.debug('end', 'Cannot end program that is not started');
      return;
    }

    this.disableEMGMonitoring();
    this._programData.running = false;
    this._programData.ended = true;

    EventBus.raise(this.events, 'onEmgEnd');
  }

  private enableEMGMonitoring() {
    /**
     * The channels from this callback can be received from device in 2 ways:
     * * all selected channels at once, then there is a constant time between next callbacks invocations, probably 50ms
     * * first channel with second and third with fourth, if so the callback with second group(3,4 channels) is invoked after approx. 5ms
     * after callback with first group(1,2 channels) and this cycle repeats in variable time from 45-55 ms
     */
    // const onEmgDataChangeCallback = (
    //   channels: { [key: number]: number },
    //   timeDelta: { readonly [key: number]: number },
    // ) => {
    //   if (this._programData.running) {
    //     for (const channel in channels) {
    //       if (!this._programData.currentData[channel]) {
    //         this._programData.currentData[channel] = [];
    //       }
    //       this._programData.currentData[channel].push({
    //         x: timeDelta[channel],
    //         y: channels[channel],
    //       });
    //     }
    //     EventBus.raise(this.events, 'onEmgDataChange', this._programData.currentData);

    //     const firstChannel = Object.keys(this._emgSignal.channels ?? {}).map(channel => +channel)[0];
    //     if (timeDelta[firstChannel]) {
    //       this._programData.duration += timeDelta[firstChannel];
    //       EventBus.raise(this.events, 'onEmgDurationChange', this._programData.duration);
    //     }

    //     if (this._programData.duration >= this._programData.totalDuration) {
    //       this.end();
    //     }
    //   }
    // };

    // this._emgFeature.onEmg = onEmgDataChangeCallback;
    // this._emgFeature.enable(this.emgSignal.channelMask);

    this._emgSignal.enable(this._emgSignal.channels);
  }

  private disableEMGMonitoring() {
    // if (!this._emgFeature) {
    //   throw new Error('Cannot disable feature monitoring without activated EMG feature');
    // }

    this._emgSignal.disable();
    // this._emgFeature.onEmg = undefined;
  }
}
