import { calculateSensitivity } from 'helpers/units';
import { GeneratedCAMGameExerciseDefinition } from 'libs/exo-session-manager/core/types/GeneratedExerciseDefinition';

export const getPredefinedSidraLegGameDrivingGameExercises = (): {
  [key: string]: GeneratedCAMGameExerciseDefinition;
} => ({
  'game_driving_game_cam_isokinetic_classic_concentric/knee': {
    type: 'cam-game',
    calibrationFlow: 'cam-isokinetic/knee/driving-game',
    tags: ['driving-game'],
    cam: {
      knee: {
        program: {
          phases: [
            {
              time: 10 * 60,
              activeMovementDirection: 'both',
              maxSpeed: 10,
              deadband: 0.2,
              coupling: {
                force: {
                  knee: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  toes: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  heel: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'toes',
                unit: 'none',
                values: ['knee', 'toes', 'heel'],
                blockAfterStart: true,
              },
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
      ankle: {
        program: null,
      },
    },
    game: {
      id: 'driving_game',
      program: {
        phases: [
          {
            speed: 10,
            blockers: 'driving-game-blokers-normal',
            gameBackground: 'random',
            carColor: 'red',
            gameSteeringMode: 'normalControl',
          },
        ],
      },
      parameters: {
        phases: [
          {
            speed: {
              default: 100,
              unit: '%',
              values: [50, 75, 100, 125, 150],
              blockAfterStart: false,
            },
            blockers: {
              default: 'driving-game-blokers-normal',
              unit: 'none',
              values: [
                'driving-game-blokers-none',
                'driving-game-blokers-little',
                'driving-game-blokers-normal',
                'driving-game-blokers-lot',
              ],
              blockAfterStart: false,
            },
            gameBackground: {
              default: 'random',
              unit: 'none',
              values: ['random', 'city', 'desert', 'mountains', 'winter'],
              blockAfterStart: true,
            },
            carColor: {
              default: 'red',
              unit: 'none',
              values: ['blue', 'gray', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'],
              blockAfterStart: true,
            },
            gameSteeringMode: {
              default: 'normalControl',
              unit: 'none',
              values: ['normalControl', 'invertControl'],
              blockAfterStart: false,
            },
          },
        ],
      },
    },
  },
  'game_driving_game_cam_isokinetic_classic_concentric/ankle': {
    type: 'cam-game',
    calibrationFlow: 'cam-isokinetic/ankle/driving-game',
    tags: ['driving-game'],
    cam: {
      ankle: {
        program: {
          phases: [
            {
              time: 10 * 60,
              activeMovementDirection: 'both',
              maxSpeed: 30,
              deadband: 0.2,
              coupling: {
                force: {
                  knee: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  toes: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  heel: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'toes',
                unit: 'none',
                values: ['knee', 'toes', 'heel'],
                blockAfterStart: true,
              },
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 30,
                unit: 'deg/s',
                values: [0.2, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
      knee: {
        program: null,
      },
    },
    game: {
      id: 'driving_game',
      program: {
        phases: [
          {
            speed: 10,
            blockers: 'driving-game-blokers-normal',
            gameBackground: 'random',
            carColor: 'red',
            gameSteeringMode: 'normalControl',
          },
        ],
      },
      parameters: {
        phases: [
          {
            speed: {
              default: 100,
              unit: '%',
              values: [50, 75, 100, 125, 150],
              blockAfterStart: false,
            },
            blockers: {
              default: 'driving-game-blokers-normal',
              unit: 'none',
              values: [
                'driving-game-blokers-none',
                'driving-game-blokers-little',
                'driving-game-blokers-normal',
                'driving-game-blokers-lot',
              ],
              blockAfterStart: false,
            },
            gameBackground: {
              default: 'random',
              unit: 'none',
              values: ['random', 'city', 'desert', 'mountains', 'winter'],
              blockAfterStart: true,
            },
            carColor: {
              default: 'red',
              unit: 'none',
              values: ['blue', 'gray', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'],
              blockAfterStart: true,
            },
            gameSteeringMode: {
              default: 'normalControl',
              unit: 'none',
              values: ['normalControl', 'invertControl'],
              blockAfterStart: false,
            },
          },
        ],
      },
    },
  },
  'game_driving_game_cam_isokinetic_classic_concentric/kneeWithSyncedAnkle': {
    type: 'cam-game',
    calibrationFlow: 'cam-isokinetic/ankle-synced/driving-game',
    tags: ['driving-game'],
    cam: {
      knee: {
        program: {
          phases: [
            {
              time: 10 * 60,
              maxSpeed: 10,
              deadband: 0.2,
              activeMovementDirection: 'both',
              coupling: {
                force: {
                  knee: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  toes: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                  heel: {
                    sensitivity: calculateSensitivity(1, 10000),
                    deadband: 0,
                  },
                },
              },
            },
          ],
        },
        parameters: {
          phases: [
            {
              activeMovementDirection: {
                default: 'both',
                unit: 'none',
                values: ['both'],
                blockAfterStart: true,
              },
              forceSource: {
                default: 'toes',
                unit: 'none',
                values: ['knee', 'toes', 'heel'],
                blockAfterStart: true,
              },
              time: {
                default: 10 * 60,
                unit: 's',
                values: [60, 2 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 25 * 60, 30 * 60, 45 * 60, 60 * 60],
                blockAfterStart: false,
              },
              maxSpeed: {
                default: 10,
                unit: 'deg/s',
                values: [0.2, 1, 2, 5, 10, 15, 20],
                blockAfterStart: false,
              },
            },
          ],
        },
      },
      ankle: {
        program: null,
        synchronized: 'knee',
      },
    },
    game: {
      id: 'driving_game',
      program: {
        phases: [
          {
            speed: 10,
            blockers: 'driving-game-blokers-normal',
            gameBackground: 'random',
            carColor: 'red',
            gameSteeringMode: 'normalControl',
          },
        ],
      },
      parameters: {
        phases: [
          {
            speed: {
              default: 100,
              unit: '%',
              values: [50, 75, 100, 125, 150],
              blockAfterStart: false,
            },
            blockers: {
              default: 'driving-game-blokers-normal',
              unit: 'none',
              values: [
                'driving-game-blokers-none',
                'driving-game-blokers-little',
                'driving-game-blokers-normal',
                'driving-game-blokers-lot',
              ],
              blockAfterStart: false,
            },
            gameBackground: {
              default: 'random',
              unit: 'none',
              values: ['random', 'city', 'desert', 'mountains', 'winter'],
              blockAfterStart: true,
            },
            carColor: {
              default: 'red',
              unit: 'none',
              values: ['blue', 'gray', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'],
              blockAfterStart: true,
            },
            gameSteeringMode: {
              default: 'normalControl',
              unit: 'none',
              values: ['normalControl', 'invertControl'],
              blockAfterStart: false,
            },
          },
        ],
      },
    },
  },
});
