import { useContext } from 'react';
import SettingsBuilder from 'libs/exo-session-manager/core/settings/SettingsBuilder';

import { CAMInstanceContext } from '../providers/CAMInstanceContext';
import { CPMElectrostimInstanceContext } from '../providers/CPMElectrostimInstanceContext';
import { CPMInstanceContext } from '../providers/CPMInstanceContext';

export const useSettings = (): SettingsBuilder => {
  const cpm = useContext(CPMInstanceContext);
  const cpmEms = useContext(CPMElectrostimInstanceContext);
  const cam = useContext(CAMInstanceContext);

  if (cpm) {
    return cpm.settings;
  }

  if (cpmEms) {
    return cpmEms.settings;
  }

  if (cam) {
    return cam.settings;
  }

  throw new Error('useSettings must be used within a Provider which requires CPM feature');
};
