import { DependencyList, EffectCallback, useEffect, useMemo, useRef } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { computed as _computed, effect, ReadonlySignal, Signal, signal as _signal } from '@preact/signals-core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useComputed as _useComputed, useSignal as _useSignal, useSignalEffect } from '@preact/signals-react';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';

export function isSignal<T>(value: ReadonlySignal<T> | T): value is ReadonlySignal<T>;
export function isSignal<T>(value: Signal<T> | T): value is Signal<T>;
export function isSignal<T>(value: Signal<T> | T): value is Signal<T> {
  return typeof value === 'object' && value instanceof Signal;
}

// Helpers below exists because we currently have components that needs to use
// signals in props and at the same time also support passing raw (plain) values.
// In future we can remove these helpers if we change our components to use
// only signals/only raw values. This probably could happen when all components
// using both versions will be fixed to use only signals.
export type ReadonlySignalOrRaw<T> = T | ReadonlySignal<T>;
export type SignalOrRaw<T> = T | Signal<T>;
export type UnwrapSignal<T> = T extends Signal<infer U> ? U : never;

export function peekOf<T>(value: SignalOrRaw<T> | ReadonlySignalOrRaw<T>) {
  return isSignal(value) ? value.peek() : value;
}

export function valueOf<T>(value: SignalOrRaw<T> | ReadonlySignalOrRaw<T>) {
  return isSignal(value) ? value.value : value;
}
export function useRawToSignal<T>(
  value: T,
  name: string,
): T extends Signal<infer U> ? ReadonlySignal<U> : ReadonlySignal<T>;
export function useRawToSignal<T>(value: SignalOrRaw<T> | ReadonlySignalOrRaw<T>, name: string) {
  const data = useMemo(
    () => ({
      current: {
        signal: isSignal(value) ? value : signal(value, name),
        isSignal: isSignal(value),
      },
    }),
    // We do not want to watch for changes in value or name because these are only initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useMemo(() => {
    if (isSignal(value)) {
      data.current = {
        signal: value,
        isSignal: true,
      };
      return;
    } else if (data.current.isSignal) {
      data.current = {
        signal: signal(value, name),
        isSignal: false,
      };
    } else {
      (data.current.signal as Signal<T>).value = value;
    }
  }, [data, name, value]);

  return data.current.signal;
}

export function useComputedOrRaw<T>(compute: () => T, deps: DependencyList): ReadonlySignal<T> | T {
  const callback = useRef(compute);
  callback.current = compute;

  return useMemo(() => {
    if (deps.some(v => isSignal(v))) {
      return _computed<T>(() => callback.current());
    }

    return callback.current();
    // We do not want to watch for compute function changing because we allow it
    // to be a closure
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useSignalEffectOrRaw(cb: EffectCallback, deps: DependencyList) {
  const callback = useRef(cb);
  callback.current = cb;

  useEffect(() => {
    if (deps.some(v => isSignal(v))) {
      return effect(() => callback.current());
    }

    return callback.current();

    // We do not want to watch for callback function changing because we allow it
    // to be a closure
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useSignal<T>(value: T, name: string) {
  const signal = _useSignal<T>(value);
  addSignalDebug(signal, 'useSignal', name);
  return signal;
}

export function useComputed<T>(compute: () => T, name: string) {
  const signal = _useComputed<T>(compute);
  addSignalDebug(signal, 'useComputed', name);
  return signal;
}

export function signal<T>(value: T, name: string) {
  const signal = _signal<T>(value);
  addSignalDebug(signal, 'signal', name);
  return signal;
}

export function computed<T = unknown>(compute: () => T, name: string) {
  const signal = _computed<T>(compute);
  addSignalDebug(signal, 'computed', name);
  return signal;
}

type SignalDebugData = { type: 'useSignal' | 'useComputed' | 'signal' | 'computed'; name: string };

const signals: WeakRef<ReadonlySignal<unknown>>[] = [];
const signalsData: WeakMap<ReadonlySignal<unknown>, SignalDebugData> = new WeakMap();
const subscriptions: (() => void)[] = [];
let observing = false;

function addSignalObserve(signal: ReadonlySignal<unknown>, data?: SignalDebugData) {
  let first = true;

  subscriptions.push(
    signal.subscribe(() => {
      if (first) {
        first = false;
        return;
      }
      console.log(
        `%c%s %c%s`,
        'color: #bada55',
        data?.name,
        'color: #46cef0',
        data?.type,
        'has changed:',
        signal.value,
      );
    }),
  );
}

function addSignalDebug(
  signal: ReadonlySignal<unknown>,
  type: 'useSignal' | 'useComputed' | 'signal' | 'computed',
  name: string,
) {
  if (!EGZOTechHostApi.instance?.options?.debugSignals) {
    return;
  }

  if (signalsData.has(signal)) {
    return;
  }

  console.debug(`%c%s %c%s`, 'color: #bada55', name, 'color: #46cef0', type, 'has been created.');

  const data = {
    type,
    name,
  } as const;

  signals.push(new WeakRef(signal));
  signalsData.set(signal, data);

  if (observing) {
    addSignalObserve(signal, data);
  }
}

if (EGZOTechHostApi.instance?.options?.debugSignals) {
  (globalThis as any).showSignals = () => {
    for (let i = signals.length - 1; i >= 0; i--) {
      const v = signals[i].deref();

      if (!v) {
        signals.splice(i, 1);
        continue;
      }

      const data = signalsData.get(v);
      console.log(`%c%s %c%s`, 'color: #bada55', data?.name, 'color: #46cef0', data?.type, v.value);
    }

    console.log('Count:', signals.length);
  };

  (globalThis as any).observeSignals = (enable = true) => {
    if (enable) {
      if (observing) {
        return;
      }

      observing = true;

      for (let i = signals.length - 1; i >= 0; i--) {
        const signal = signals[i].deref();

        if (!signal) {
          signals.splice(i, 1);
          continue;
        }

        const data = signalsData.get(signal);
        addSignalObserve(signal, data);
      }

      return;
    }

    observing = false;
    subscriptions.forEach(v => v());
    subscriptions.splice(0);
  };
}

export { Signal, useSignalEffect };
export type { ReadonlySignal };
