import { Flex, FlexProps, Text, useBreakpointValue } from '@chakra-ui/react';
import theme from 'config/theme';
import { ChakraSize } from 'types/chakra';

const badgeChannelSizes = {
  sm: theme.sizes['8'],
  md: theme.sizes['12'],
  lg: theme.sizes['14'],
  xl: theme.sizes['20'],
} as const;
const badgeFontVariants = {
  sm: 'openSans16',
  md: 'openSans24',
  lg: 'openSans36',
  xl: 'openSans36',
} as const;

type ChannelBadgeProps = {
  channel?: number;
  label?: string;
  active?: boolean;
  mr?: string;
  size: ChakraSize<'sm' | 'md' | 'lg' | 'xl'>;
  testId?: string;
  onClick?(): void;
} & FlexProps;

export const ChannelBadge = ({
  channel,
  active = true,
  label = typeof channel === 'number' ? (channel + 1).toString() : undefined,
  mr,
  size,
  testId,
  onClick,
  ...props
}: ChannelBadgeProps) => {
  const currentSize = useBreakpointValue(typeof size === 'string' ? [size] : size) ?? 'md';
  return (
    <Flex
      justify="center"
      align="center"
      userSelect="none"
      h={badgeChannelSizes[currentSize]}
      w={badgeChannelSizes[currentSize]}
      minW={badgeChannelSizes[currentSize]}
      backgroundColor={
        (active === undefined || active) && channel !== undefined ? `channel.${channel + 1}` : 'gray.300'
      }
      borderRadius="oval"
      textAlign="center"
      mr={mr}
      data-testid={testId}
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'initial'}
      {...props}
    >
      <Text variant={badgeFontVariants[currentSize]} color={active ? 'white' : 'gray.500'} fontWeight="semibold">
        {label}
      </Text>
    </Flex>
  );
};
