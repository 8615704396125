import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useSignals } from '@preact/signals-react/runtime';
import colors, { Channel } from 'config/theme/colors';
import { ReadonlySignal, useComputed, useSignal } from 'helpers/signal';

import { SVGBackgroundBar } from './SVGBackgroundBar';
import { SVGLevelBar } from './SVGLevelBar';
import { SVGSlider } from './SVGSlider';
import { SVGYAxis } from './SVGYAxis';

interface Props {
  channel: number;
  currentValue: ReadonlySignal<number>;
  maxValue: ReadonlySignal<number>;
  thresholdLevel: number;
  onLevelChange?: (level: number) => void;
  isDisabled?: boolean;
  isThresholdDisabled?: boolean;
  color?: string;
  lightColor?: string;
}

export const EMGBar = memo(
  ({
    channel,
    currentValue,
    maxValue,
    onLevelChange,
    thresholdLevel,
    isDisabled,
    isThresholdDisabled,
    color,
    lightColor,
  }: Props) => {
    useSignals();
    const [currentMaxValue, setCurrentMaxValue] = useState(maxValue.value);
    const [isRescaling, setIsRescaling] = useState(false);
    const maxValueRef = useRef(currentMaxValue);
    const primaryColor = color ?? colors.channel[(channel + 1) as Channel];
    const primaryLightColor = lightColor ?? colors.channel.light[(channel + 1) as Channel];

    const updateCurrentMax = useCallback(() => {
      setCurrentMaxValue(v => {
        const delta = v - maxValueRef.current;
        return Math.abs(delta) < 1 ? maxValueRef.current : maxValueRef.current + delta / 2;
      });
    }, []);

    useEffect(() => {
      if (!isRescaling || isDisabled) {
        return;
      }
      const frameId = requestAnimationFrame(updateCurrentMax);

      return () => {
        cancelAnimationFrame(frameId);
      };
    }, [isRescaling, currentMaxValue, updateCurrentMax, isDisabled]);

    useEffect(() => {
      setIsRescaling(maxValue.value !== currentMaxValue);
      maxValueRef.current = maxValue.value;
    }, [currentMaxValue, maxValue.value]);

    // These values strictly reflects dimensions in mockup
    // yHeight is vertical dimenstion of the bar and
    // ySpace is space between top of SVG and the bar beginning
    const yHeight = 438.4;
    const ySpace = 33.5;
    const defaultBackgroundLevelBar = useSignal(100, 'EMGBar.defaultBackgroundLevelBar');
    const svgBackgroundBar = useComputed(() => {
      const value = Math.abs(currentValue.value);
      return maxValue.value ? (value / maxValue.value) * 100 : 0;
    }, 'EMGBar.svgBackgroundBar');

    return (
      <Box w={{ base: '52', '2xl': '19.1875rem' }} boxSizing="content-box">
        <svg viewBox={`-50 0 307 510`}>
          {!isDisabled && (
            <SVGYAxis
              yAxisMaxNoLines={10}
              maxValue={(currentMaxValue * (yHeight + ySpace)) / yHeight}
              yHeight={ySpace + yHeight}
            />
          )}
          <SVGBackgroundBar
            xOffset={56}
            yOffset={ySpace}
            yHeight={yHeight}
            level={defaultBackgroundLevelBar}
            color={isDisabled ? colors.gray[300] : primaryLightColor}
          />
          {!isDisabled && (
            <SVGBackgroundBar
              xOffset={56}
              yOffset={ySpace}
              yHeight={yHeight}
              level={svgBackgroundBar}
              color={primaryColor}
            />
          )}
          {!isDisabled && !isThresholdDisabled && (
            <SVGLevelBar xOffset={29} yOffset={ySpace} yHeight={yHeight} level={thresholdLevel} />
          )}
          <SVGSlider
            level={thresholdLevel}
            xOffset={192}
            yOffset={ySpace}
            yHeight={yHeight}
            color={primaryColor}
            onLevelChange={onLevelChange}
            isDisabled={isDisabled || isThresholdDisabled}
          />
        </svg>
      </Box>
    );
  },
);

EMGBar.displayName = 'EMGBar';
