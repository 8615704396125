import { ChangeEvent, FC } from 'react';
import { GridItem } from '@chakra-ui/react';
import { SingleValue } from 'chakra-react-select';
import { unavailableExercisesByDevice } from 'config/defaultConfigProps';
import { useTrainingList } from 'hooks/lists/useTrainingList';
import { useAppSelector } from 'hooks/store';
import { useDevice } from 'libs/exo-session-manager/react';
import { TrainingFilterType } from 'slices/trainingListSlice';
import { SelectData } from 'types';

import { MainInput } from 'components/form/MainInput';
import { SearchIcon } from 'components/icons';

import { TrainingFilterItem } from './TrainingFilterItem';

export const TrainingFilterInput: FC = () => {
  const { getOptionsFromFilter, setFilter, setSearch, getCurrentSearch } = useTrainingList();
  const { selectedDevice } = useDevice();

  const { availableFilters } = useAppSelector(state => state.trainingList);

  const availableExercises = selectedDevice
    ? availableFilters?.trainingTypes.filter(
        el => !unavailableExercisesByDevice[selectedDevice.type]?.includes(el.name),
      )
    : availableFilters?.trainingTypes;

  const getInputList = () => [
    {
      name: TrainingFilterType.TRAINING,
      options: getOptionsFromFilter(availableExercises, 'training.types.'),
      onChange: (value: SingleValue<SelectData>) => {
        setFilter(value!.value, TrainingFilterType.TRAINING);
      },
    },
  ];

  const searchTraining = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
  };

  return (
    <>
      {getInputList().map(item => (
        <TrainingFilterItem key={item.name} item={item} />
      ))}
      <GridItem>
        <MainInput
          placeholder="application.search"
          width="full"
          height="12"
          icon={<SearchIcon />}
          iconMarginRight={'1'}
          value={getCurrentSearch()}
          onChange={searchTraining}
        />
      </GridItem>
    </>
  );
};
