import { useCallback, useContext, useMemo } from 'react';
import { logger } from 'helpers/logger';
import { CalibrationFlow } from 'libs/exo-session-manager/core';
import { ConnectedChannelToMuscle } from 'types';

import { CPMElectrostimInstanceContext } from '../providers/CPMElectrostimInstanceContext';

import { useCalibrationFlowState } from './useCalibrationFlowState';

type Props = {
  connectedChannelsToMuscles?: ConnectedChannelToMuscle[];
  areElectrodesConnected?: boolean;
  selectedInstance?: number;
} | null;

export const useFlowEMSCalibration = (flow: CalibrationFlow) => {
  const [connectElectrodesData] = useCalibrationFlowState(flow, 'connect-electrodes');
  return { ...useEMSCalibration(connectElectrodesData as Props), connectElectrodesData };
};

export const useEMSCalibration = (connectElectrodesData: Props) => {
  const emsCalibration = useBaseEMSCalibration(connectElectrodesData);
  if (!emsCalibration) {
    throw new Error('useEMSCalibration must be used within a Provider which requires EMS feature');
  }
  return emsCalibration;
};

export const useBaseEMSCalibration = (connectElectrodesData: Props, selectedEMSInstanceId?: number) => {
  const cpmEmsInstance = useContext(CPMElectrostimInstanceContext);

  const activeFeature = useMemo(() => {
    if (!cpmEmsInstance) {
      return null;
    }

    if (selectedEMSInstanceId !== undefined) {
      cpmEmsInstance.selectEMSInstance(selectedEMSInstanceId);
    }
    if (cpmEmsInstance) {
      return {
        setProgram: cpmEmsInstance.setProgram.bind(cpmEmsInstance),
        emsCalibration: cpmEmsInstance.emsCalibration,
      };
    }

    // TODO: add missing contexts (Electrostim, ElectrostimEMG)

    return null;
  }, [cpmEmsInstance, selectedEMSInstanceId]);

  const start = useCallback(() => {
    if (!connectElectrodesData?.connectedChannelsToMuscles) {
      logger.warn('useEMSCalibration', 'Channels are not mapped. Check if electrodes are connected to the muscles');
    }
    if (!activeFeature) {
      logger.warn('useEMSCalibration', 'EMSCalibration cannot be started without EMS Feature');
      return;
    }
    if (connectElectrodesData?.connectedChannelsToMuscles && !activeFeature.emsCalibration.data.peek().active) {
      const channels = connectElectrodesData.connectedChannelsToMuscles.map(
        connectedChannel => connectedChannel.channelIndex,
      );

      if (!activeFeature.emsCalibration.data.peek().calibrated) {
        activeFeature.setProgram();
        activeFeature.emsCalibration.start(channels);
      } else {
        activeFeature.emsCalibration.resume(channels);
      }
    }
  }, [activeFeature, connectElectrodesData?.connectedChannelsToMuscles]);

  if (activeFeature) {
    return {
      ...activeFeature.emsCalibration.data.value,
      start,
      selectPort: activeFeature.emsCalibration.selectPort.bind(activeFeature.emsCalibration),
      setIntensity: activeFeature.emsCalibration.setIntensity.bind(activeFeature.emsCalibration),
      storeCalibrationData: activeFeature.emsCalibration.storeCalibrationData.bind(activeFeature.emsCalibration),
      restoreCalibrationData: activeFeature.emsCalibration.restoreCalibrationData.bind(activeFeature.emsCalibration),
      end: activeFeature.emsCalibration.end.bind(activeFeature.emsCalibration),
    };
  }
  return null;
};
