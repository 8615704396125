import { CAMActiveMovementDirection, CAMProgram } from '@egzotech/exo-session/features/cam';
import { Logger } from '@egzotech/universal-logger-js';
import { Signal, signal } from 'helpers/signal';

import { SensorsName } from '../types';
import {
  CAMProgramAdditionalConfiguration,
  isGeneratedCAMProgramDefinitionPrimary,
} from '../types/GeneratedCAMProgramDefinition';
import {
  GeneratedCAMLikeExerciseDefinition,
  GeneratedExerciseDefinition,
  isCAMExerciseDefinition,
} from '../types/GeneratedExerciseDefinition';
import { MotorPlacement } from '../types/GeneratedProgramDefinition';

import {
  CAMComprehensiveParameters,
  CAMPhasicParameters,
  isCAMComprehensiveParameterId,
  isCAMPhasicParameterId,
  SettingsParameters,
  SettingsRequiredMethods,
} from './SettingsBuilder';

/**
 * An auxiliary function that determines whether the string is a sensor name
 * @param str Checked string
 * @returns true if string is valid SensorsName
 */
export function isSensorsName(str: string): str is SensorsName {
  return ['knee', 'heel', 'toes', 'torque', 'extension'].includes(str);
}

/**
 * An auxiliary function that determines whether the string is a CAM active movement direction
 * @param str Checked string
 * @returns true if string is valid CAMActiveMovementDirection
 */
export function isCAMActiveMovementDirection(str: string): str is CAMActiveMovementDirection {
  return ['toMin', 'toMax', 'both'].includes(str);
}

export type CAMPhasicSignalParameters = {
  [key in keyof CAMPhasicParameters]: Signal<SettingsParameters<CAMPhasicParameters>[key]>;
};

export type CAMGeneralSignalParameters = {
  [key in keyof CAMComprehensiveParameters]: Signal<SettingsParameters<CAMComprehensiveParameters>[key]>;
};

export type CAMSignalParameters = CAMGeneralSignalParameters & {
  phases: CAMPhasicSignalParameters[];
};

export default class CAMSettings implements SettingsRequiredMethods {
  private _parameters: CAMSignalParameters;

  static readonly logger = Logger.getInstance('ExerciseSettings');

  constructor(
    private readonly originalDefinition: GeneratedCAMLikeExerciseDefinition,
    private readonly primaryMotorKey: MotorPlacement,
  ) {
    let phases: CAMSignalParameters['phases'] = [];
    let general: CAMGeneralSignalParameters = {
      maxTime: signal(
        {
          default: 0,
          values: [],
          blockAfterStart: false,
        },
        'CAMSettings._parameters.maxTime',
      ),
      maxTorque: signal(
        {
          default: 5,
          values: [],
          blockAfterStart: false,
        },
        'CAMSettings._parameters.maxTorque',
      ),
      maxBackwardForceLimit: signal(
        {
          default: 5,
          values: [],
          blockAfterStart: false,
        },
        'CAMSettings._parameters.maxBackwardForceLimit',
      ),
    };
    const primaryProgramDefinition = originalDefinition.cam[primaryMotorKey];
    if (primaryProgramDefinition && isGeneratedCAMProgramDefinitionPrimary(primaryProgramDefinition)) {
      if (primaryProgramDefinition.parameters?.phases) {
        phases = primaryProgramDefinition.parameters.phases.map((phase, i) => ({
          repetitions: signal(
            {
              currentValue: primaryProgramDefinition.program.phases[i].repetitions,
              default: phase?.repetitions?.default ?? 0,
              values: phase?.repetitions?.values ?? [],
              blockAfterStart: phase?.repetitions?.blockAfterStart ?? false,
              previousValue: primaryProgramDefinition.program.phases[i].repetitions,
            },
            'CAMSettings._parameters.phases.repetitions',
          ),
          maxSpeed: signal(
            {
              currentValue: primaryProgramDefinition.program.phases[i].maxSpeed,
              default: phase?.maxSpeed?.default ?? 0,
              values: phase?.maxSpeed?.values ?? [],
              blockAfterStart: phase?.maxSpeed?.blockAfterStart ?? false,
              previousValue: primaryProgramDefinition.program.phases[i].maxSpeed,
            },
            'CAMSettings._parameters.phases.maxSpeed',
          ),
          time: signal(
            {
              currentValue: primaryProgramDefinition.program.phases[i].time,
              default: phase?.time?.default ?? 0,
              values: phase?.time?.values ?? [],
              blockAfterStart: phase?.time?.blockAfterStart ?? false,
              previousValue: primaryProgramDefinition.program.phases[i].time,
            },
            'CAMSettings._parameters.phases.time',
          ),
          endActiveMovementForce: signal(
            {
              currentValue: primaryProgramDefinition.program.phases[i].endActiveMovementForce,
              default: phase?.endActiveMovementForce?.default ?? 0,
              values: phase?.endActiveMovementForce?.values ?? [],
              blockAfterStart: phase?.endActiveMovementForce?.blockAfterStart ?? false,
              previousValue: primaryProgramDefinition.program.phases[i].endActiveMovementForce,
            },
            'CAMSettings._parameters.phases.endActiveMovementForce',
          ),
          startActiveMovementForce: signal(
            {
              currentValue: primaryProgramDefinition.program.phases[i].startActiveMovementForce,
              default: phase?.startActiveMovementForce?.default ?? 0,
              values: phase?.startActiveMovementForce?.values ?? [],
              blockAfterStart: phase?.startActiveMovementForce?.blockAfterStart ?? false,
              previousValue: primaryProgramDefinition.program.phases[i].startActiveMovementForce,
            },
            'CAMSettings._parameters.phases.startActiveMovementForce',
          ),
          forceSource: signal(
            {
              currentValue: primaryProgramDefinition.program.phases[i].forceSource,
              default: phase?.forceSource?.default ?? 'knee', // FIXME: All this logic is wrong. What to do here?
              values: phase?.forceSource?.values ?? [],
              blockAfterStart: phase?.forceSource?.blockAfterStart ?? false,
              previousValue: primaryProgramDefinition.program.phases[i].forceSource,
            },
            'CAMSettings._parameters.phases.forceSource',
          ),
          activeMovementDirection: signal(
            {
              currentValue: primaryProgramDefinition.program.phases[i].activeMovementDirection,
              default: phase?.activeMovementDirection?.default ?? 'both',
              values: phase?.activeMovementDirection?.values ?? [],
              blockAfterStart: phase?.activeMovementDirection?.blockAfterStart ?? false,
              previousValue: primaryProgramDefinition.program.phases[i].activeMovementDirection,
            },
            'CAMSettings._parameters.phases.activeMovementDirection',
          ),
          deadband: signal(
            {
              currentValue: primaryProgramDefinition.program.phases[i].deadband,
              default: phase?.deadband?.default ?? 0,
              values: phase?.deadband?.values ?? [],
              blockAfterStart: phase?.deadband?.blockAfterStart ?? false,
              previousValue: primaryProgramDefinition.program.phases[i].deadband,
            },
            'CAMSettings._parameters.phases.deadband',
          ),
        }));
      }
      if (primaryProgramDefinition.parameters) {
        general = Object.keys(primaryProgramDefinition.parameters).reduce<CAMGeneralSignalParameters>((acc, curr) => {
          if (isCAMComprehensiveParameterId(curr) && primaryProgramDefinition?.parameters?.[curr]) {
            acc[curr]!.value.currentValue = primaryProgramDefinition.program[curr];
            acc[curr]!.value.default = primaryProgramDefinition.parameters[curr]?.default ?? 0;
            acc[curr]!.value.values = primaryProgramDefinition.parameters[curr]?.values ?? [];
            acc[curr]!.value.previousValue = primaryProgramDefinition.program[curr];
          }
          return acc;
        }, general);
      }
    }
    this._parameters = {
      phases,
      ...general,
    };
  }

  get parameters() {
    return this._parameters;
  }

  getPrimaryCAMProgram(definition = this.originalDefinition) {
    const primaryCAMProgram = definition.cam[this.primaryMotorKey]?.program;
    if (!primaryCAMProgram) {
      throw new Error('Wrong primary motor key - primary CAM program is not defined');
    }
    return primaryCAMProgram as CAMProgram & CAMProgramAdditionalConfiguration;
  }

  setRepetition(repetition: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (repetition < 1) {
      throw new Error(`Given repetition: ${repetition} is invalid. Exercise must have at least 1 repetition`);
    }
    if (!this._parameters.phases[phaseIndex].repetitions?.peek()?.values?.includes(repetition)) {
      throw new Error(
        `Cannot set given repetition: ${repetition}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].repetitions?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].repetitions!.value = {
      ...this._parameters.phases[phaseIndex].repetitions!.peek(),
      currentValue: repetition,
    };
  }

  setTime(time: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (time < 0) {
      throw new Error(`Given duration: ${time} is invalid. Duration must be at least 1 second`);
    }
    if (!this._parameters.phases[phaseIndex].time?.peek()?.values?.includes(time)) {
      throw new Error(
        `Cannot set given time: ${time}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].time?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].time!.value = {
      ...this._parameters.phases[phaseIndex].time!.peek(),
      currentValue: time,
    };
  }

  setMaxSpeed(maxSpeed: number, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (maxSpeed < 0) {
      throw new Error(`Given max speed: ${maxSpeed} is invalid. Max speed must be at least greater than zero`);
    }
    if (!this._parameters.phases[phaseIndex].maxSpeed?.peek()?.values?.includes(maxSpeed)) {
      throw new Error(
        `Cannot set given max speed: ${maxSpeed}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].maxSpeed?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].maxSpeed!.value = {
      ...this._parameters.phases[phaseIndex].maxSpeed!.peek(),
      currentValue: maxSpeed,
    };
  }
  setMaxTorque(maxTorque: number) {
    if (maxTorque < 0) {
      throw new Error(`Given max torque: ${maxTorque} is invalid. Max torque must be at least greater than zero`);
    }
    this._parameters.maxTorque!.value = {
      ...this._parameters.maxTorque!.peek(),
      currentValue: maxTorque,
    };
  }
  setMaxBackwardForceLimit(maxBackwardForceLimit: number) {
    if (maxBackwardForceLimit < 0) {
      throw new Error(
        `Given maxBackwardForceLimit: ${maxBackwardForceLimit} is invalid. Max maxBackwardForceLimit must be at least greater than zero`,
      );
    }
    this._parameters.maxBackwardForceLimit!.value = {
      ...this._parameters.maxBackwardForceLimit!.peek(),
      currentValue: maxBackwardForceLimit,
    };
  }

  setActiveMovementDirection(activeMovementDirection: CAMActiveMovementDirection, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (
      !this._parameters.phases[phaseIndex].activeMovementDirection?.peek()?.values?.includes(activeMovementDirection)
    ) {
      throw new Error(
        `Cannot set given activeMovementDirection: ${activeMovementDirection}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].activeMovementDirection?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].activeMovementDirection!.value = {
      ...this._parameters.phases[phaseIndex].activeMovementDirection!.peek(),
      currentValue: activeMovementDirection,
    };
  }

  setForceSource(forceSource: SensorsName, phaseIndex = 0) {
    this.validatePhaseParameter(phaseIndex);
    if (!this._parameters.phases[phaseIndex].forceSource?.peek()?.values?.includes(forceSource)) {
      throw new Error(
        `Cannot set given forceSource: ${forceSource}. It must be one of the value from: [${
          this._parameters.phases[phaseIndex].forceSource?.peek()?.values
        }]`,
      );
    }
    this._parameters.phases[phaseIndex].forceSource!.value = {
      ...this._parameters.phases[phaseIndex].forceSource!.peek(),
      currentValue: forceSource,
    };
  }

  setMaxTime(time: number) {
    if (time < 0) {
      throw new Error(`Given duration: ${time} is invalid. Duration must be at least 1 second`);
    }
    if (!this._parameters.maxTime?.peek()?.values?.includes(time)) {
      throw new Error(
        `Cannot set given time: ${time}. It must be one of the value from: [${
          this._parameters.maxTime?.peek()?.values
        }]`,
      );
    }
    this._parameters.maxTime!.value = {
      ...this._parameters.maxTime!.peek(),
      currentValue: time,
    };
  }

  setDeadband(value: number, phaseIndex = 0) {
    this._parameters.phases[phaseIndex].deadband!.value = {
      ...this._parameters.phases[phaseIndex].deadband!.peek(),
      currentValue: value,
    };
  }

  updateDefinition(definition: GeneratedExerciseDefinition) {
    if (!isCAMExerciseDefinition(definition)) {
      throw new Error('Cannot update non CPM definition in CPMSettings');
    }

    this._parameters.phases.forEach((parameter, phaseIndex) => {
      Object.entries(parameter).forEach(([k, v]) => {
        if (
          typeof v?.peek()?.currentValue === 'number' &&
          isCAMPhasicParameterId(k) &&
          k !== 'activeMovementDirection' &&
          k !== 'forceSource'
        ) {
          this.getPrimaryCAMProgram(definition).phases[phaseIndex][k] = v.peek().currentValue as number;
        } else if (
          typeof v?.peek()?.currentValue === 'string' &&
          isCAMActiveMovementDirection(v.peek().currentValue as string) &&
          isCAMPhasicParameterId(k) &&
          k === 'activeMovementDirection'
        ) {
          this.getPrimaryCAMProgram(definition).phases[phaseIndex][k] = v.peek()
            .currentValue as CAMActiveMovementDirection;
        } else if (
          typeof v?.peek()?.currentValue === 'string' &&
          isSensorsName(v.peek().currentValue as string) &&
          isCAMPhasicParameterId(k) &&
          k === 'forceSource'
        ) {
          this.getPrimaryCAMProgram(definition).phases[phaseIndex][k] = v.peek().currentValue as SensorsName;
          this.getPrimaryCAMProgram(definition).phases[phaseIndex].coupling.force = {
            [v.peek().currentValue as SensorsName]: structuredClone(
              this.getPrimaryCAMProgram(this.originalDefinition).phases[phaseIndex].coupling.force![
                v.peek().currentValue as SensorsName
              ],
            ),
          };
        }
      });
      if (typeof parameter.time?.peek()?.currentValue === 'number') {
        delete this.getPrimaryCAMProgram(definition).phases[phaseIndex].repetitions;
      } else if (typeof parameter.repetitions?.peek()?.currentValue === 'number') {
        delete this.getPrimaryCAMProgram(definition).phases[phaseIndex].time;
      }
    });
    Object.keys(this._parameters).forEach(k => {
      if (isCAMComprehensiveParameterId(k) && typeof this._parameters[k]?.peek()?.currentValue === 'number') {
        this.getPrimaryCAMProgram(definition)[k] = this._parameters[k]?.peek()?.currentValue;
      }
    });
  }

  private validatePhaseParameter(phaseIndex: number) {
    if (
      phaseIndex < 0 ||
      phaseIndex >= (this.getPrimaryCAMProgram().phases.length ?? 0) ||
      !this.getPrimaryCAMProgram().phases[phaseIndex]
    ) {
      throw new Error(`There is no phaseIndex with given index: ${phaseIndex} for this exercise`);
    }
  }
}
