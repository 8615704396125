import { useCallback, useEffect, useMemo, useRef } from 'react';
import emergencyStopAudio from 'assets/sounds/emergencyStop.wav';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useModal } from 'hooks/useModal';
import { useDevice, useProgramSelection } from 'libs/exo-session-manager/react';
import { emergencyDialogOff, emergencyDialogOn } from 'slices/emergencyActionSlice';

import { ModalType } from './Modal';

export const useEmergencyStopButtonModal = () => {
  const navigate = useAppNavigate();
  const { selectedProgram } = useProgramSelection();
  const dispatch = useAppDispatch();
  const { selectedDevice } = useDevice();

  const audio = useMemo(() => new Audio(emergencyStopAudio), []);

  const [openEmergencyModal, closeEmergencyModal, updateEmergencyModal] = useModal(ModalType.EMERGENCY_BUTTON, {});

  const { isOpened, name: dialogName } = useAppSelector(state => state.modal);
  const prevDialogNameRef = useRef(dialogName);

  useEffect(() => {
    // TODO: This logic might be probably better integrated with the dialog system.
    if (prevDialogNameRef.current !== ModalType.EMERGENCY_BUTTON.name) {
      if (isOpened && dialogName && dialogName === ModalType.EMERGENCY_BUTTON.name) {
        dispatch(emergencyDialogOn());
      }
    } else {
      if (!isOpened || dialogName !== ModalType.EMERGENCY_BUTTON.name) {
        dispatch(emergencyDialogOff());
      }
    }
    prevDialogNameRef.current = dialogName;
  }, [dispatch, isOpened, dialogName]);

  const onClose = useCallback(() => {
    selectedDevice?.emergencyButton?.clear();
    closeEmergencyModal();

    audio.pause();
    audio.currentTime = 0;
  }, [audio, closeEmergencyModal, selectedDevice?.emergencyButton]);

  const onNavigate = useCallback(() => {
    selectedDevice?.emergencyButton?.clear();

    closeEmergencyModal();
    audio.pause();
    audio.currentTime = 0;

    if (selectedProgram) {
      navigate('/specialist');
    }
  }, [audio, closeEmergencyModal, navigate, selectedDevice?.emergencyButton, selectedProgram]);

  useEffect(() => {
    const enableAudio = async () => {
      audio.loop = true;
      try {
        await audio.play();
      } catch (error) {
        // HTMLAudioElement.play() is not available on a page without user interactions
        // when the flag --autoplay-policy=no-user-gesture-required
        // is not set
        console.error(error);
      }
    };

    if (selectedDevice?.emergencyButton) {
      selectedDevice.emergencyButton.onPressed = () => {
        enableAudio();
        openEmergencyModal({
          type: selectedProgram ? 'emergencyTrainingStop' : 'emergencyStop',
          callback: onClose,
          secondCallback: onNavigate,
        });
      };

      selectedDevice.emergencyButton.onReleased = () => {
        updateEmergencyModal({ type: selectedProgram ? 'emergencyTrainingAction' : 'emergencyAction' });
      };

      selectedDevice.emergencyButton.onLock = () => {
        openEmergencyModal({
          type: selectedProgram ? 'emergencyTrainingAction' : 'emergencyAction',
          callback: onClose,
          secondCallback: onNavigate,
        });
      };
    }
  }, [audio, dispatch, onClose, onNavigate, openEmergencyModal, selectedDevice, selectedProgram, updateEmergencyModal]);
};
