import { convertVersionToString } from 'hooks/useFirmwareVersion';
import { DeviceManager } from 'libs/exo-session-manager/core';
import {
  meissaOTModulesVersionConfiguration,
  sidraLegModulesVersionConfiguration,
} from 'libs/exo-session-manager/core/global/modules-configurations';
import { URLSearchParams } from 'url';

import { RESTMethod, RESTResponse } from './RESTClient';

const REQUEST_TIMEOUT = 100;
class MockedApi {
  private updateStarted = false;
  private updateProgress = 0;

  private mock: Record<string, () => unknown> = {
    // 'GET:/device/update': () => {
    //   return {
    //     version: '1.0.0',
    //     available: {
    //       version: '1.0.1',
    //       source: 'local',
    //     },
    //   };
    // },
    // 'POST:/device/update': () => {
    //   this.updateStarted = true;
    //   this.updateProgress = 0;
    //   return {};
    // },
    // 'POST:/device/update/restart': () => {
    //   window.location.reload();
    //   return {};
    // },
    // 'GET:/device/update/status': () => {
    //   if (this.updateStarted && this.updateProgress < 100) {
    //     this.updateProgress += 5;
    //   }
    //   if (this.updateProgress > 5) {
    //     // simulate error during the update
    //     return undefined;
    //   }
    //   return {
    //     progress: this.updateProgress,
    //     running: this.updateStarted,
    //     completed: this.updateProgress === 100,
    //   };
    // },
    'dummy:GET:/api/device/update/firmware/valid-version': () => {
      const deviceManager = (window as any).deviceManager as DeviceManager;
      if (!deviceManager.selectedDevice || !deviceManager.selectedDevice.type) {
        throw new Error('Cannot mock expected firmware without a device type');
      }
      return deviceManager.selectedDevice.type === 'sidra-leg'
        ? Object.fromEntries(
            Object.entries(sidraLegModulesVersionConfiguration.modules)
              .filter(([, v]) => v)
              .map(([k, v]) => [k, convertVersionToString(v!.version)]),
          )
        : deviceManager.selectedDevice.type === 'meissa-ot'
        ? Object.fromEntries(
            Object.entries(meissaOTModulesVersionConfiguration.modules)
              .filter(([, v]) => v)
              .map(([k, v]) => [k, convertVersionToString(v!.version)]),
          )
        : null;
    },
  };

  isMocked(method: RESTMethod, path: string, params?: URLSearchParams) {
    const deviceManager = (window as any).deviceManager as DeviceManager;

    if (
      (deviceManager.getDummyController() || params?.get('deviceId')?.startsWith('dummy')) &&
      typeof this.mock[`dummy:${method}:${path}`] === 'function'
    ) {
      return true;
    }

    return typeof this.mock[`${method}:${path}`] === 'function';
  }

  mockedFetch(method: RESTMethod, path: string): Promise<RESTResponse<any>> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const deviceManager = (window as any).deviceManager as DeviceManager;
        let mock = this.mock[`${method}:${path}`];

        if (deviceManager.getDummyController() && typeof this.mock[`dummy:${method}:${path}`] === 'function') {
          mock = this.mock[`dummy:${method}:${path}`];
        }

        if (mock) {
          try {
            resolve(new Response(JSON.stringify(mock())));
          } catch (ex) {
            resolve(new Response(ex instanceof Error ? ex.stack?.toString() : '', { status: 500 }));
          }
        } else {
          reject();
        }
      }, REQUEST_TIMEOUT);
    });
  }
}

export const mockedApi = new MockedApi();
