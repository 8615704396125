import { FC } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GridItem } from '@chakra-ui/react';

interface Props {
  onClick: () => void;
}

export const GridMobileIcon: FC<Props> = ({ onClick }: Props) => {
  return (
    <GridItem alignSelf="center" justifySelf="flex-end">
      <GiHamburgerMenu cursor="pointer" onClick={onClick} />
    </GridItem>
  );
};
